import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRouteSnapshot, ActivatedRoute } from '@angular/router';
import { AuthService, HelperService } from '@app/core';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {

  constructor(
    private authService: AuthService,
    private helperService: HelperService,
    private activatedRoute: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: any
  ) { }

  canActivate(route: ActivatedRouteSnapshot) {
    return this.authService.authenticate().then((isLoggedIn: any) => {
      if (!isPlatformBrowser(this.platformId)) {
        this.helperService.navigateToUrl('/auth-token?loading=true');
        return true;
      }
      else if (isLoggedIn) {
        if ((route.routeConfig!.path === 'login')) {
          this.helperService.navigateToUrl('/');
        }
        return true;
      }
      else {
        if (!(route.routeConfig!.path === 'login')) {
          let action = (route.queryParams.action)? route.queryParams.action : '';
          let data = (route.queryParams.data)? route.queryParams.data:'';
          this.helperService.navigateToUrl('/login?action=' + action + '&data=' + data);
        }
        return true;
      }
    });
  }
}
