<div class="relative">
    <input [(ngModel)]="searchText" (keyup)="changeSearchText($event)" class="border border-gray-light px-4 py-2 pl-8 md:pl-4 text-sm text-gray-300 placeholder-gray-light rounded-full w-full focus:outline-none focus:border-primary focus:shadow-card" type="text" placeholder="Search Templates">
    <i class="fa fa-search text-gray-light text-sm absolute transform top-1/2 -translate-y-1/2 left-3 md:left-auto md:right-4"></i>

    <!-- Search Filter Tags Dropdown: Mobile/Tablet -->
    <a href="javascript:void(0);" (click)="toggleTagsDropdown()" class="bg-white text-gray-300 text-xs leading-7 font-medium border-l border-gray-light flex md:hidden items-center absolute transform top-1/2 -translate-y-1/2 right-3">
        <span class="px-2 sm:px-4 max-w-30 sm:max-w-xxs md:max-w-full truncate">{{ activeTag.tag }} Templates</span>
        <i class="fa fa-caret-down text-base"></i>
    </a>
    <div *ngIf="showDropDown" class="md:hidden bg-white shadow-dropdown absolute rounded z-10 p-0.5 dropdown-arrow-top-right" style="top: 52px; right: 0;">
        <div class="min-w-max-content divide-y divide-light w-64 overflow-auto max-h-52 rounded text-gray-600 text-xs px-2.5 filter-tags-dropdown">
            <a *ngFor="let tag of allTags" href="javascript:void(0);" [routerLink]="'/' + tag.slug" class="flex items-center justify-between py-2" [class]="activeTag.tag == tag.tag ? 'text-gray-300 font-medium' : ''">
                {{ tag.tag }} Templates
                <img *ngIf="activeTag && activeTag.slug == tag.slug" src="assets/images/icon-check-primary.svg" class="inline-block w-3.5 ml-1">
            </a>
        </div>
    </div>
</div>