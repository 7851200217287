import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { HelperService, AuthService, ApiService } from '@app/core';
import { TemplateService } from './template.service';
import { TemplateLibraryService } from './template-library.service';
import { FilterTagsModalComponent } from '@app/shared/components/modal/filter-tags-modal/filter-tags-modal.component';
import { CopyWorkflowModalComponent } from './modals/copy-workflow-modal/copy-workflow-modal.component'
import { ActivatedRoute, Params, Router } from "@angular/router";


declare var $: any;

@Component({
  selector: 'app-template-library',
  templateUrl: './template-library.component.html'
})
export class TemplateLibraryComponent implements OnInit {

  data: any = {};
  isProcessing: Boolean = false;
  searchText: string = '';
  showWorkflowGuidePopup: boolean = true;
  openPremiumTemplateAccuontUpgradePopup: boolean = false;

  constructor(
    public helperService: HelperService,
    private templateService: TemplateService,
    public authService: AuthService,
    public apiService: ApiService,
    public templateLibraryService: TemplateLibraryService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: any,
  ) { }

  ngOnInit() {

    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    this.activeRoute.url.subscribe(url => {
      this.data.slug = this.router.url.replace(/^\//, "");
      this.getTemplates();

      // this.getDescriptionBySlug(this.router.url.replace(/^\//, ""))
    });

    // Use local storage for workflow popup to shown.
    if (localStorage.getItem('isWorkflowPopupShown')) {
      this.showWorkflowGuidePopup = false;
    } else {
      this.showWorkflowGuidePopup = true;
      localStorage.setItem('isWorkflowPopupShown', 'true');
    }
    // this.handleCopyWorkflowModal();

  }

  getTemplates(refresh_from_drive: boolean = false) {

    this.isProcessing = true;
    this.data.templates = [];

    let request = this.templateService.get(refresh_from_drive, this.data.slug);
    request.subscribe((response: any) => {
      this.data.templates = response.templates;
      this.data.permissions = response.permissions;
      this.data.landingPageTag = response.landingPagetag;
      this.data.slug_discription = {
        "description": response.description,
        "tag": response.tag,
      };
      this.data.allTags = response.allTags;
      this.isProcessing = false;

      var self = this;
      if (isPlatformBrowser(this.platformId)) {
        $(document).scroll(function () {
          if (self.showWorkflowGuidePopup) {
            if ($('#bottomFixedStop').offset().top + $('#bottomFixedStop').height() >= $('#bottomFixedStopArea').offset().top - 10)
              $('#bottomFixedStop').css({ 'position': 'unset', 'transform': 'unset', 'margin': '0 auto' });

            if ($(document).scrollTop() + window.innerHeight < $('#bottomFixedStopArea').offset().top) {
              $('#bottomFixedStop').css({ 'position': 'fixed', 'transform': 'translateX(-50%)' }); // restore when you scroll up
              $('#bottomFixedStopArea').css({ 'height': '7rem' });
            } else {
              $('#bottomFixedStopArea').css({ 'height': '0' });
            }
          }
        });
      }
    });
  }


  handleCopyWorkflowModal() {

    let condition = this.helperService.isMobile() &&
      !localStorage.getItem('CopyWorkflowModal') &&
      (!this.authService.isLoggedIn || (this.authService.isLoggedIn && this.authService.user.show_copy_workflow_modal));

    if (condition) {
      setTimeout(() => this.helperService.openModal(CopyWorkflowModalComponent), 1000)
    }
  }

  openTagFilterModal() {

    this.helperService.openModal(FilterTagsModalComponent, {
      // tags: this.helperService.getTags(),
      heading: 'Search template library by tags',
      // preSelectedTags: this.data.activeTags
    });
  }

  setActiveTag(event) {
    this.data.activeTag = [];
    if (event && event.tag) {
      this.data.activeTag = [event.tag];
    }
  }

  // setSearchText(value) {
  //   this.searchText = value;
  // }

  childSearchText(searchText) {
    this.searchText = searchText;
  }

  // openWorkflowGuidPopup() {
  //   this.helperService.openModal(CopyWorkflowModalComponent);
  //   // let condition = this.helperService.isMobile() &&
  //   //   !localStorage.getItem('CopyWorkflowModal') &&
  //   //   (!this.authService.isLoggedIn || (this.authService.isLoggedIn && this.authService.user.show_copy_workflow_modal));

  //   // if (condition) {
  //   //   setTimeout(() => this.helperService.openModal(CopyWorkflowModalComponent), 1000)
  //   // }
  // }

  openWorkflowGuidPopup() {
    this.showWorkflowGuidePopup = this.showWorkflowGuidePopup ? false : true;
  }


  getDescriptionBySlug(slug: any) {
    this.templateLibraryService.getDescription(slug).subscribe(response => this.data.templateDiscription = response);
  }

  showPremiumTemplateAccuontUpgradePopup(value) {
    this.openPremiumTemplateAccuontUpgradePopup = value;
  }
}