import { Injectable, Inject } from '@angular/core';
import { PLATFORM_ID } from "@angular/core";
import { isPlatformBrowser } from '@angular/common';

import { ApiService } from './api.service';
import { HelperService } from './helper.service';
import { ActivatedRoute } from '@angular/router';

const URL_ME = 'me';
const URL_LOGOUT = 'logout';

@Injectable({
	providedIn: 'root'
})
export class AuthService {

	user: any = null;
	isLoggedIn: boolean = false;

	constructor(private apiService: ApiService, private helperService: HelperService,private activatedRoute: ActivatedRoute, @Inject(PLATFORM_ID) private platformId: any) {
		// this.authenticate();
	}

	authenticate(forceAuthRefresh = false) {
		return new Promise((resolve) => {

			let user = localStorage.getItem('user');

			if (!isPlatformBrowser(this.platformId)) {
				resolve(this.isLoggedIn);
			} else if (!forceAuthRefresh && user) {
				this.user = JSON.parse(localStorage.getItem('user') as string);
				this.isLoggedIn = true;
				resolve(this.isLoggedIn);
			} else {
				let request = this.apiService.get(URL_ME);

				request.subscribe((user: any) => {
					if (user && user.email) {
						localStorage.setItem('user', JSON.stringify(user));
						this.user = user;
						this.isLoggedIn = true;
					} else {
						this.user = null;
						this.isLoggedIn = false;
						localStorage.removeItem('user');
					}
					resolve(this.isLoggedIn);
				});
			}
		});
	}

	logout() {
		this.user = null;
		this.isLoggedIn = false;
		localStorage.clear();
		this.apiService.get(URL_LOGOUT).subscribe();
		return true;
	}

	canCreateTeamDrives() {
		return this.user.can_create_team_drives == 1;
	}

}
