<app-navigation-tabs [active]="'SD_library'"></app-navigation-tabs>

<div class="min-h-90-vh lg:min-h-70-vh xl:min-h-screen">
    <!-- Spinner -->
    <div class="text-center py-4" *ngIf="isProcessing">
        <i class="fa fa-spinner fa-spin fa-2x text-primary" aria-hidden="true"></i>
    </div>

    <div class="bg-white" *ngIf="!isProcessing">
        <div class="bg-light bg-opacity-75">
            <div class="md:container mx-auto px-4 py-5">
                <!-- Content -->
                <app-landing-section [data]="data.slug_discription" *ngIf="!isProcessing"></app-landing-section>
            </div>
        </div>
    </div>

    <div class="md:container mx-auto px-4 pt-4 sm:pt-6" *ngIf="!isProcessing">
        <div class="flex flex-col md:flex-row mb-6">
            <!-- Sidebar -->
            <div class="md:max-w-xxs w-full hidden md:block">
                <!-- Tags -->
                <h5 class="text-gray-900 text-xs leading-6 tracking-wide border-b border-dashed border-gray-light pb-3 mb-4">Categories</h5>
                <app-tags [allTags]="data.allTags" [selectedTagUrl]="data.landingPageTag.url"  (selectedTag)="setActiveTag($event)"></app-tags>
            </div>

            <div class="flex-1 md:pl-4 lg:pl-7">
                <template-search-filter-tags (outputSearchText)="childSearchText($event)" [allTags]="data.allTags" [selectedTagUrl]="data.landingPageTag.url" (selectedTag)="setActiveTag($event)"></template-search-filter-tags>

                <!-- Action Links -->
                <ul class="flex items-center flex-wrap justify-end list-none text-xs font-medium text-gray-300 tracking-wide my-2.5" *ngIf="!isProcessing">
                    <li class="inline-flex items-center">
                        <a class="inline-flex items-center" href="javascript:void(0);" title="Copy Template Workflow Guide" (click)="openWorkflowGuidPopup()">
                            <img class="inline-block w-5 h-5 p-px mr-1" src="assets/images/icon-eye.svg"> Workflow Guide
                        </a>
                    </li>
                    <li class="inline-flex items-center" class="inline-flex items-center border-l border-gray-light pl-2 sm:pl-3 ml-2 sm:ml-3">
                        <a href="javascript:void(0);" title="Refresh Templates" (click)="getTemplates(true)">
                            <img class="inline-block w-5 h-5 p-px mr-1" src="assets/images/icon-refresh-o.svg"> Refresh
                        </a>
                    </li>
                </ul>

                <!-- No StartDeck Templates -->
                <div class="text-center my-8" *ngIf="!isProcessing && data.templates && (data.templates | SearchByTag:searchText:data.activeTag).length === 0">
                    <h5 class="text-gray-900 text-sm"> No Template Found </h5>
                </div>

                <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 lg:gap-6" *ngIf="data.templates && data.templates.length > 0">
                    <app-template *ngFor="let template of data.templates | SearchByTag:searchText:data.activeTag" [template]="template" [permissions]="data.permissions" (premiumTemplateAccessDenied)="showPremiumTemplateAccuontUpgradePopup($event)"></app-template>
                </div>
                
            </div>
        </div>

        <!-- Copy Workflow Guide -->
        <div class="fixed bottom-0 z-1000 p-3 w-full" id="bottomFixedStop" style="max-width: 816px; left: 50%; transform: translateX(-50%);" *ngIf="!isProcessing && showWorkflowGuidePopup">
            <div class="bg-white p-2.5 rounded-lg relative" style="box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.8);">
                <a href="javascript:void(0);" class="inline-flex items-center justify-center h-4 w-4 rounded-full bg-gray-300 absolute" style="top: -6px; right: -6px;" (click) = "showWorkflowGuidePopup = false">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </a>

                <div class="md:border-t md:border-b md:border-primary md:border-opacity-50 rounded-md">
                    <nav>
                        <ol class="rounded-md overflow-hidden md:flex md:border-l md:border-r md:border-primary md:border-opacity-50 bg-light bg-opacity-75 cursor-default tracking-wide">
                            <li class="relative overflow-hidden md:flex-1">
                                <div class="border border-primary md:border-opacity-50 overflow-hidden border-b-0 rounded-t-md md:border-0">
                                    <div class="group">
                                        <span class="px-3 py-2 flex items-center">
                                            <span class="flex-shrink-0">
                                                <span class="w-8 h-8 flex items-center justify-center bg-primary rounded-full">
                                                    <span class="text-white -mt-1">1</span>
                                                </span>
                                            </span>
                                            <span class="ml-2 min-w-0 flex flex-col">
                                                <span class="font-medium text-sm leading-5 mb-1 text-gray-300">Select a Template to copy</span>
                                                <span class="text-gray-900 text-xs leading-4">Just looking? <a class="text-primary underline text-opacity-75"  [routerLink]="'/template-library/startdeck-demo-doc'" href="javascript:void(0);">Try the Demo Doc</a></span>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </li>
                    
                            <li class="relative overflow-hidden md:flex-1">
                                <div class="border border-primary md:border-opacity-50 overflow-hidden md:border-0">
                                    <div aria-current="step">
                                    <span class="px-3 py-2 flex items-center md:pl-6">
                                        <span class="flex-shrink-0">
                                            <span class="w-8 h-8 flex items-center justify-center border-2 border-success bg-success rounded-full">
                                                <span class="text-white -mt-1">2</span>
                                            </span>
                                        </span>
                                        <span class="ml-2 min-w-0 flex flex-col">
                                            <span class="font-medium text-sm leading-5 mb-1 text-gray-300">Copy to your G Drive</span>
                                            <span class="text-gray-900 text-xs leading-4">Sign-in and copy</span>
                                        </span>
                                    </span>
                                    </div>
                        
                                    <div class="hidden absolute top-0 left-0 w-3 inset-0 md:block" aria-hidden="true">
                                        <svg class="h-full w-full text-primary text-opacity-50" viewBox="0 0 12 82" fill="none" preserveAspectRatio="none">
                                            <path d="M0.5 0V31L10.5 41L0.5 51V82" stroke="currentcolor" vector-effect="non-scaling-stroke" />
                                        </svg>
                                    </div>
                                </div>
                            </li>
                    
                            <li class="relative overflow-hidden md:flex-1">
                                <div class="border border-primary md:border-opacity-50 overflow-hidden border-t-0 rounded-b-md md:border-0">
                                    <div class="group">
                                        <span class="px-3 py-2 flex items-center md:pl-6">
                                            <span class="flex-shrink-0">
                                                <span class="w-8 h-8 flex items-center justify-center border-2 border-warning bg-warning rounded-full">
                                                    <span class="text-white -mt-1">3</span>
                                                </span>
                                            </span>
                                            <span class="ml-2 min-w-0 flex flex-col">
                                                <span class="font-medium text-sm leading-5 mb-1 text-gray-300">Open in Google Docs</span>
                                                <span class="text-gray-900 text-xs leading-4">Customize or Use As Is</span>
                                            </span>
                                        </span>
                                    </div>
                        
                                    <div class="hidden absolute top-0 left-0 w-3 inset-0 md:block" aria-hidden="true">
                                        <svg class="h-full w-full text-primary text-opacity-50" viewBox="0 0 12 82" fill="none" preserveAspectRatio="none">
                                            <path d="M0.5 0V31L10.5 41L0.5 51V82" stroke="currentcolor" vector-effect="non-scaling-stroke" />
                                        </svg>
                                    </div>
                                </div>
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="pt-4" id="bottomFixedStopArea" *ngIf="!isProcessing && showWorkflowGuidePopup"></div>

<!-- Premium Content Template Alert -->
<app-premium-template-upgrade-account *ngIf="openPremiumTemplateAccuontUpgradePopup"></app-premium-template-upgrade-account>
