import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from '@app/core';

@Component({
  selector: 'app-navigation-tabs',
  templateUrl: './navigation-tabs.component.html'
})
export class NavigationTabsComponent implements OnInit {

  @Input() active: any;

  constructor(
    public authService: AuthService,
  ) { }

  ngOnInit(): void {
  }

}
