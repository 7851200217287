<div class="min-h-90-vh lg:min-h-70-vh xl:min-h-screen">
    <!-- Banner -->
    <section class="bg-white bg-no-repeat bg-contain bg-bottom" style="background-image: url('assets/images/bg-welcome.png');">
        <div class="md:container mx-auto px-4 py-6 md:py-10 lg:py-12">
            <div class="flex flex-wrap">

                <div class="w-full md:w-5/12 text-center md:text-left">
                    <h1 class="text-xl leading-7 md:text-2xl md:leading-10 lg:text-3xl lg:leading-12 font-bold text-gray-300 mb-3 sm:mb-4">
                        Build Great
                        <div class="text-rotate-vertical">
                            <span class="text-primary">Technical</span>
                            <span class="text-success">Appraisal</span>
                            <span class="text-warning">Inspection</span>
                            <span class="text-danger">Business</span>
                        </div>
                        <br class="hidden md:block">
                        Reports with Google Docs
                    </h1>
                    <p class="text-xs sm:text-sm md:font-medium text-gray-600 mb-3 md:mb-4">
                        Simplify with form driven docs and build reports faster with fewer errors and greater consistency.
                    </p>
                    <p class="text-xs sm:text-sm md:font-medium text-gray-600 mb-4 md:mb-8">
                        Organize and centralize templates/branding, project files, signatures and tracking.
                    </p>
                    <a [routerLink]="'/template-library'" class="bg-success py-3 w-80  rounded text-white font-bold hidden text-center md:inline-block hover:shadow-btn transition-all duration-300">Try it free</a>
                       


                    <div class="gap-1 hidden items-center justify-center mt-4 md:flex text-gray-600 w-80">
                        <div class="w-full border border-gray-600"></div>

                        <div class="inline-flex w-full gap-1">
                            <div class="">
                                <svg width="18" height="18" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="13" height="13" transform="translate(0.5)" fill="#6A6A6A"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.79981 2.27499C1.79981 2.0164 1.90253 1.76841 2.08538 1.58556C2.26823 1.40271 2.51622 1.29999 2.77481 1.29999H3.52101C3.74296 1.29989 3.9583 1.37553 4.13144 1.5144C4.30459 1.65327 4.42517 1.84706 4.47326 2.06374L4.93866 4.15869C4.99245 4.40121 4.95164 4.65513 4.82456 4.86858C4.69747 5.08204 4.4937 5.23894 4.25486 5.30724L3.64841 5.48079C3.38191 5.55684 3.23046 5.84154 3.33641 6.09829C3.71346 7.00986 4.26659 7.83812 4.96413 8.53566C5.66167 9.23321 6.48993 9.78633 7.40151 10.1634C7.65826 10.2693 7.94296 10.1179 8.01901 9.85139L8.19256 9.24494C8.26086 9.00609 8.41775 8.80232 8.63121 8.67524C8.84466 8.54816 9.09858 8.50734 9.34111 8.56114L11.4361 9.02654C11.6527 9.07463 11.8465 9.19521 11.9854 9.36835C12.1243 9.54149 12.1999 9.75683 12.1998 9.97879V10.725C12.1998 10.9836 12.0971 11.2316 11.9142 11.4144C11.7314 11.5973 11.4834 11.7 11.2248 11.7H10.2498C9.50296 11.7 8.77886 11.6025 8.08791 11.4205C6.6456 11.0375 5.33017 10.28 4.27496 9.22483C3.21976 8.16962 2.46228 6.8542 2.07931 5.41189C1.89332 4.70632 1.79938 3.97966 1.79981 3.24999V2.27499Z" fill="#FFF8F8"/>
                                </svg>
                            </div>

                            <p class="whitespace-nowrap" style="line-height: 1.1rem;">(401) 237-0603</p>
                        </div>

                        <div class="w-full border border-gray-600"></div>
                    </div>

                    <a href="https://calendar.app.google/bNJtrDScWT1mF1Mf6" target="_blank" class="bg-primary py-3 mt-4 w-80 rounded text-white font-bold hidden text-center md:inline-block hover:shadow-btn transition-all duration-300">Book a Demo</a>
                        
                </div>

                <div class="w-full md:w-7/12 text-center">
                    <img src="assets/images/startdeck-writer.png" alt="StartDeck Writer" class="inline-block">

                    <a [routerLink]="'/template-library'" class="bg-success py-3 px-20 rounded text-white font-bold md:hidden text-center block mt-4 hover:shadow-btn transition-all duration-300">Try it free</a>
                    

                    <div class="gap-1 md:hidden items-center justify-center mt-4 flex text-gray-600 ">
                        <div class="w-full border border-gray-600"></div>

                        <div class="inline-flex gap-1">
                            <div class="">
                                <svg width="18" height="18" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="13" height="13" transform="translate(0.5)" fill="#6A6A6A"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.79981 2.27499C1.79981 2.0164 1.90253 1.76841 2.08538 1.58556C2.26823 1.40271 2.51622 1.29999 2.77481 1.29999H3.52101C3.74296 1.29989 3.9583 1.37553 4.13144 1.5144C4.30459 1.65327 4.42517 1.84706 4.47326 2.06374L4.93866 4.15869C4.99245 4.40121 4.95164 4.65513 4.82456 4.86858C4.69747 5.08204 4.4937 5.23894 4.25486 5.30724L3.64841 5.48079C3.38191 5.55684 3.23046 5.84154 3.33641 6.09829C3.71346 7.00986 4.26659 7.83812 4.96413 8.53566C5.66167 9.23321 6.48993 9.78633 7.40151 10.1634C7.65826 10.2693 7.94296 10.1179 8.01901 9.85139L8.19256 9.24494C8.26086 9.00609 8.41775 8.80232 8.63121 8.67524C8.84466 8.54816 9.09858 8.50734 9.34111 8.56114L11.4361 9.02654C11.6527 9.07463 11.8465 9.19521 11.9854 9.36835C12.1243 9.54149 12.1999 9.75683 12.1998 9.97879V10.725C12.1998 10.9836 12.0971 11.2316 11.9142 11.4144C11.7314 11.5973 11.4834 11.7 11.2248 11.7H10.2498C9.50296 11.7 8.77886 11.6025 8.08791 11.4205C6.6456 11.0375 5.33017 10.28 4.27496 9.22483C3.21976 8.16962 2.46228 6.8542 2.07931 5.41189C1.89332 4.70632 1.79938 3.97966 1.79981 3.24999V2.27499Z" fill="#FFF8F8"/>
                                </svg>
                            </div>

                            <p class=" whitespace-nowrap" style="line-height: 1.1rem;">(401) 237-0603</p>
                        </div>

                        <div class="w-full border border-gray-600"></div>
                    </div>
                    
                    <a href="https://calendar.google.com/calendar/appointments/schedules/AcZssZ2oq_YwylBMZhiFu38htjWTylLbJK_dYh8hgfY_IahGIO6tMVFP090tyhKTPq7lhBlUSrpB7di5" target="_blank" class="bg-primary py-3 mt-4  px-20 rounded text-white font-bold md:hidden text-center block  hover:shadow-btn transition-all duration-300">Book a Demo</a>
                    
                </div>

            </div>
        </div>
    </section>

    <!-- StartDeck in 5 Minutes video -->
    <section class="py-8 max-w-5xl flex  mx-auto" >
        <iframe class=" hidden  md:inline-block mx-auto " width="856" height="525.50" [src]="youtubeUrl" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        <iframe class=" md:hidden  block mx-auto" width="560" height="315"  [src]="youtubeUrl" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    </section>   

    <!-- StartDeck Workflow -->
    <section class="text-center mx-auto bg-white">
        <picture>
            <source media="(max-width: 767px)" srcset="assets/images/startdeck-workflow-automation/startdeck-workflow-automation-sm.png">
            <img src="assets/images/startdeck-workflow-automation/startdeck-workflow-automation.png" alt="StartDeck Workflow Automation" class="inline-block">
        </picture>
    </section>

    <!-- Document Production -->
    <section class="">
        <div class="md:container mx-auto text-center py-6 md:py-10 sm:py-12 px-4">
            <h2 class="text-base sm:text-lg leading-7 md:text-xl md:leading-8 lg:text-2xl font-bold text-success mb-4 md:mb-6">Power tools for Google Workspace to Streamline your Document Production</h2>
            <ul class="bullet-success text-gray-600 text-left text-xs sm:text-sm leading-5 sm:leading-6 sm:font-medium pl-4 mb-3">
                <li class="mb-3 sm:mb-4">Managing technical report production has never been easy.</li>
                <li class="mb-3 sm:mb-4">Templates need to be on brand, on point and centralized for you and your team. Document templates, content and subsections should be a click away.</li>
                <li>Reports need to be consistent, efficient to complete and easily trackable. Signature and deliveries need to be managed.</li>
            </ul>

            <a class="inline-block mb-3" href="javascript:void(0);" [routerLink]="'/login'">
                <img class="inline-block" width="395px" title="StartDeck" alt="Enter StartDeck" src="assets/images/logo/enter-startdeck.svg">
            </a>

            <ul class="bullet-success text-gray-600 text-left text-xs sm:text-sm leading-5 sm:leading-6 sm:font-medium pl-4 mb-6">
                <li class="mb-3 sm:mb-4">We've streamlined the entire process, from templates, to document automation, to delivery and tracking.</li>
                <li class="mb-3 sm:mb-4">Watch your production take off with the tech writing software for Google Workspace that gives your team the competitive edge. The sky's the limit.</li>
            </ul>

            <a [routerLink]="'/template-library'" class="bg-success py-3 px-20 rounded text-white font-bold md:inline-block text-center block hover:shadow-btn transition-all duration-300">Try it free</a>
        </div>
    </section>

    <!-- Business Templates & Content Library -->
    <section class="bg-white">
        <div class="md:container mx-auto text-center py-6 md:py-10 sm:py-12 px-4">
            <div class="flex flex-wrap">
                <div class="w-full md:w-7/12 text-center md:text-left">
                    <h2 class="text-base sm:text-lg leading-7 md:text-xl md:leading-8 lg:text-2xl font-bold text-warning mb-4 md:mb-6">Business Templates & Content Library</h2>
                    <ul class="bullet-warning text-gray-600 text-left text-xs sm:text-sm leading-5 sm:leading-6 sm:font-medium pl-4 mb-4 md:mb-6">
                        <li class="mb-3 sm:mb-4">StartDeck provides turn-key professional templates for everyday business use.</li>
                        <li class="mb-3 sm:mb-4">Quickly find and complete standard business documents, and more complex docs such as proposals, RFP’s and more.</li>
                        <li>All our templates are fully customizable! What will you create?</li>
                    </ul>
                </div>
                <div class="w-full md:w-5/12 text-center sm:pt-2 md:pt-0">
                    <picture>
                        <source media="(max-width: 1024px)" srcset="assets/images/file/file-sm.png">
                        <img src="assets/images/file/file.png" alt="Business Templates & Content Library" class="inline-block">
                    </picture>
                </div>
            </div>
        </div>
    </section>

    <!-- StartDeck Writer -->
    <section class="">
        <div class="md:container mx-auto text-center py-6 md:py-10 sm:py-12 px-4">
            <div class="flex flex-wrap">
                <div class="w-full md:w-5/12 text-center sm:pt-2 md:pt-0 order-last md:order-none">
                    <picture>
                        <source media="(max-width: 1024px)" srcset="assets/images/doc/doc-sm.png">
                        <img src="assets/images/doc/doc.png" alt="StartDeck Writer" class="inline-block">
                    </picture>
                </div>
                <div class="w-full md:w-7/12 text-center md:text-left">
                    <h2 class="text-base sm:text-lg leading-7 md:text-xl md:leading-8 lg:text-2xl font-bold text-primary mb-4 md:mb-6">StartDeck Writer</h2>
                    <ul class="bullet-primary text-gray-600 text-left text-xs sm:text-sm leading-5 sm:leading-6 sm:font-medium pl-4 mb-4 md:mb-6">
                        <li class="mb-3 sm:mb-4">Quickly populate a document with project info with merge fields. Not only can you pre populate documents and reports, updates are just as easy.</li>
                        <li>Your content library is always a sidebar away for quick access for your goto content.</li>
                    </ul>
                </div>
            </div>
        </div>
    </section>

    <!-- StartDeck eSignatures -->
    <section class="bg-white">
        <div class="md:container mx-auto text-center py-6 md:py-10 sm:py-12 px-4">
            <div class="flex flex-wrap">
                <div class="w-full md:w-7/12 text-center md:text-left">
                    <h2 class="text-base sm:text-lg leading-7 md:text-xl md:leading-8 lg:text-2xl font-bold text-primary mb-4 md:mb-6">StartDeck eSignatures</h2>
                    <!-- <img class="inline-block" title="StartDeck" alt="StartDeck" src="assets/images/logo/startdeck-esignature.png"> -->
                    <ul class="bullet-primary text-gray-600 text-left text-xs sm:text-sm leading-5 sm:leading-6 sm:font-medium pl-4 mb-4 md:mb-6">
                        <li class="mb-3 sm:mb-4">Document signatures, delivery and tracking.</li>
                        <li>eSignatures are seamlessly integrated with your report workflow.</li>
                    </ul>
                </div>
                <div class="w-full md:w-5/12 text-center sm:pt-2 md:pt-0">
                    <picture>
                        <source media="(max-width: 1024px)" srcset="assets/images/signing-doc/signing-doc-sm.png">
                        <img src="assets/images/signing-doc/signing-doc.png" alt="StartDeck eSignatures" class="inline-block">
                    </picture>
                </div>
            </div>
        </div>
    </section>

    <!-- StartDeck Tables & Maps -->
    <section class="">
        <div class="md:container mx-auto text-center py-6 md:py-10 sm:py-12">
            <div class="flex flex-wrap">
                <div class="w-full md:w-6/12 text-center sm:px-4 sm:pt-2 md:pt-0 order-last md:order-none">
                    <picture>
                        <source media="(max-width: 1280px)" srcset="assets/images/sheet/sheet-sm.png">
                        <img src="assets/images/sheet/sheet.png" alt="StartDeck Tables" class="inline sm:pr-4 md:pr-8">
                    </picture>
                    <picture>
                        <source media="(max-width: 1280px)" srcset="assets/images/map/map-sm.png">
                        <img src="assets/images/map/map.png" alt="StartDeck Maps" class="inline">
                    </picture>
                </div>
                <div class="w-full md:w-6/12 px-4 text-center md:text-left">
                    <h2 class="text-base sm:text-lg leading-7 md:text-xl md:leading-8 lg:text-2xl font-bold text-success mb-4 md:mb-6">StartDeck Tables & Maps</h2>
                    <ul class="text-left mb-4 md:mb-6 bullet-success text-gray-600 font-normal text-xs leading-5 pl-4 sm:font-medium sm:text-sm sm:leading-6">
                        <li class="mb-3 sm:mb-4">Tables for everyday business, integrated with our templates. Project timelines, deliverable schedules, pricing tables and more. Of course, you can create your own with the full power of Google Sheets!</li>
                        <li>Need a location map? Just enter the location and StartDeck will build a custom Google Map ready to merge!</li>
                    </ul>
                </div>
            </div>
        </div>
    </section>

    <!-- StartDeck Dashboard -->
    <section class="bg-white">
        <div class="md:container mx-auto text-center py-6 md:py-10 sm:py-12 px-4">
            <div class="flex flex-wrap">
                <div class="w-full md:w-7/12 text-center md:text-left">
                    <h2 class="text-base sm:text-lg leading-7 md:text-xl md:leading-8 lg:text-2xl font-bold text-primary mb-4 md:mb-6">StartDeck Dashboard</h2>
                    <ul class="bullet-primary text-gray-600 text-left text-xs sm:text-sm leading-5 sm:leading-6 sm:font-medium pl-4 mb-4 md:mb-6">
                        <li>Browse templates, create and manage projects with the StartDeck Dashboard for Google Drive. Our Dashboard is a <a class="text-primary" href="https://web.dev/progressive-web-apps/" target="_blank">Progressive Web App</a> and works great on a phone, tablet or desktop. It’s super fast and syncs across all your devices.</li>
                    </ul>
                </div>
                <div class="w-full md:w-5/12 text-center sm:pt-2 md:pt-0">
                    <picture>
                        <source media="(max-width: 1024px)" srcset="assets/images/drive/drive-sm.png">
                        <img src="assets/images/drive/drive.png" alt="StartDeck Dashboard" class="inline-block">
                    </picture>
                </div>
            </div>
        </div>
    </section>

    <!-- Google Workspace automation -->
    <section class="">
        <div class="md:container mx-auto text-center py-6 md:py-10 sm:py-12 px-4">
            <div class="w-full lg:w-8/12 text-center px-3 md:px-4 mx-auto mb-12">
                <h2 class="text-base sm:text-lg leading-7 md:text-xl md:leading-8 lg:text-2xl font-bold text-warning mb-4 md:mb-6">Power up with Google Workspace automation!</h2>
                <p class="text-gray-600 font-normal text-xs leading-5 sm:font-medium sm:text-sm sm:leading-6 mb-4 sm:mb-6">
                    You’ll need two Add-ons to complete the platform, StartDeck Writer and StartDeck Tables. These
                    Add-ons power StartDeck’s workflow automation and are free while we’re in beta mode. Install these
                    Add-ons, use a template from our library and get started!
                </p>
                <a href="https://gsuite.google.com/marketplace/app/startdeck/249982793988" target="_blank">
                    <picture>
                        <source media="(max-width: 1024px)" srcset="assets/images/g-suite-marketplace/g-suite-marketplace-sm.png">
                        <img src="assets/images/g-suite-marketplace/g-suite-marketplace.png" alt="Power up with Google Workspace automation!" class="inline-block">
                    </picture>
                </a>
            </div>
        </div>
    </section>
</div>