<div class="bg-white">
    <div class="container mx-auto">
        <div class="flex sm:items-center px-4 py-3">
            <div class="pr-2">
                <a class="text-gray-300" href="javascript:void(0);" (click)="back()">
                    <i class="fa fa-angle-left font-bold text-3xl"></i>
                </a>
            </div>
            <div class="text-gray-300 font-medium flex-1 text-center text-sm sm:text-base">
                Power up with Google Workspace automation!
            </div>
        </div>
    </div>
</div>

<!-- Google Workspace automation -->
<div class="container mx-auto px-4 py-4 min-h-90-vh lg:min-h-70-vh xl:min-h-screen mb-20">
    <div class="w-full lg:w-8/12 text-center md:px-4 mx-auto pt-4 sm:pt-8">
        <p class="text-gray-600 text-xs sm:text-sm sm:font-medium mb-4 sm:mb-6">You’ll need two Add-ons to complete the platform, StartDeck Writer and StartDeck Tables. These Add-ons power StartDeck’s workflow automation and are free while we’re in beta mode. Install these Add-ons, use a template from our library and get started!</p>
        <a href="https://gsuite.google.com/marketplace/app/startdeck/249982793988" target="_blank">
            <picture>
                <source media="(max-width: 1024px)" srcset="assets/images/g-suite-marketplace/g-suite-marketplace-sm.png">
                <img src="assets/images/g-suite-marketplace/g-suite-marketplace.png" alt="Power up with Google Workspace automation!" class="inline-block">
            </picture>
        </a>
    </div>
</div>