import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Params, Router } from "@angular/router";
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-external-redirects',
  template: `
    <div class="container mx-auto px-4 py-4 min-h-90-vh lg:min-h-70-vh text-center">
      <div class="mt-8"><i class="fa fa-spinner fa-spin fa-2x"></i></div>
    </div>`
})
export class ExternalRedirectsComponent implements OnInit {

  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: any,
  ) { }

  ngOnInit(): void {
    if (!isPlatformBrowser(this.platformId)) return;
    this.activeRoute.url.subscribe(url => {
      var slug = this.router.url.replace(/^\//, "");

      var link = '';

      if (slug == 'support') {
        link = "https://calendar.google.com/calendar/appointments/schedules/AcZssZ0dGI-MzRLA2HRnbUqHLmNp14Fm_oGpRDONtXIi5_0f18WN3gs-lLjifK66iXSUq00mMG3u18zB";
      }

      if (slug == 'demo') {
        link = "https://calendar.app.google/bNJtrDScWT1mF1Mf6";
      }

      if (slug == 'onboard') {
        link = "https://calendar.google.com/calendar/appointments/schedules/AcZssZ0sM0JyvpP5jtFec5wS1zbEa6z_TGPw2w-KY85kdb7nHFIjI2Q5u_QI_l8mcN2l1DhfF27zUmt3"
      }

      window.location.href = link;
    });
  }
}
