<!-- Workflow Guide: Mobile/Tab View -->
<div class="md:border-t md:border-b md:border-primary md:border-opacity-50 rounded-md mb-4 block md:hidden">
    <nav>
        <ol class="rounded-md overflow-hidden md:flex md:border-l md:border-r md:border-primary md:border-opacity-50 bg-light cursor-default">
            <li class="relative overflow-hidden md:flex-1">
                <div class="border border-primary md:border-opacity-50 overflow-hidden border-b-0 rounded-t-md md:border-0">
                    <div class="group">
                        <span class="px-4 py-3 flex items-start">
                            <span class="flex-shrink-0">
                                <span class="w-10 h-10 flex items-center justify-center bg-primary rounded-full">
                                    <span class="text-white -mt-1 text-lg">1</span>
                                </span>
                            </span>
                            <span class="mt-0.5 ml-4 min-w-0 flex flex-col">
                                <span class="font-medium text-sm sm:text-base -mt-1 text-gray-300">Select a Template to copy</span>
                                <span class="text-gray-900 text-xs">Just looking? <a class="text-primary underline" href="https://stg.startdeck.com/template-library/startdeck-demo-doc">Try the Demo Doc</a></span>
                            </span>
                        </span>
                    </div>
                </div>
            </li>
    
            <li class="relative overflow-hidden md:flex-1">
                <div class="border border-primary md:border-opacity-50 overflow-hidden md:border-0">
                    <div aria-current="step">
                    <span class="px-4 py-3 flex items-start md:pl-6">
                        <span class="flex-shrink-0">
                        <span class="w-10 h-10 flex items-center justify-center border-2 border-success bg-success rounded-full">
                            <span class="text-white -mt-1 text-lg">2</span>
                        </span>
                        </span>
                        <span class="mt-0.5 ml-4 min-w-0 flex flex-col">
                            <span class="font-medium text-sm sm:text-base -mt-1 text-gray-300">Copy to your G Drive</span>
                            <span class="text-gray-900 text-xs">Sign-in and copy</span>
                        </span>
                    </span>
                    </div>
        
                    <div class="hidden absolute top-0 left-0 w-3 inset-0 md:block" aria-hidden="true">
                    <svg class="h-full w-full text-primary text-opacity-50" viewBox="0 0 12 82" fill="none" preserveAspectRatio="none">
                        <path d="M0.5 0V31L10.5 41L0.5 51V82" stroke="currentcolor" vector-effect="non-scaling-stroke" />
                    </svg>
                    </div>
                </div>
            </li>
    
            <li class="relative overflow-hidden md:flex-1">
                <div class="border border-primary md:border-opacity-50 overflow-hidden border-t-0 rounded-b-md md:border-0">
                    <div class="group">
                        <span class="px-4 py-3 flex items-start md:pl-6">
                            <span class="flex-shrink-0">
                            <span class="w-10 h-10 flex items-center justify-center border-2 border-warning bg-warning rounded-full">
                                <span class="text-white -mt-1 text-lg">3</span>
                            </span>
                            </span>
                            <span class="mt-0.5 ml-4 min-w-0 flex flex-col">
                                <span class="font-medium text-sm sm:text-base -mt-1 text-gray-300">Open in Google Docs</span>
                                <span class="text-gray-900 text-xs">Customize or Use As Is</span>
                            </span>
                        </span>
                    </div>
        
                    <div class="hidden absolute top-0 left-0 w-3 inset-0 md:block" aria-hidden="true">
                    <svg class="h-full w-full text-primary text-opacity-50" viewBox="0 0 12 82" fill="none" preserveAspectRatio="none">
                        <path d="M0.5 0V31L10.5 41L0.5 51V82" stroke="currentcolor" vector-effect="non-scaling-stroke" />
                    </svg>
                    </div>
                </div>
            </li>
        </ol>
    </nav>
</div>

<!-- Workflow Guide: Desktop View -->
<div class="rounded-md my-4 hidden md:block">
    <nav>
        <ol class="rounded-md overflow-hidden bg-light bg-opacity-50 cursor-default tracking-normal">
            <li class="relative overflow-hidden">
                <div class="border border-primary border-opacity-50 overflow-hidden border-b-0 rounded-t-md">
                    <div class="group">
                        <span class="px-2 py-2 pb-1.5 flex items-start">
                            <span class="flex-shrink-0">
                                <span class="w-6 h-6 flex items-center justify-center bg-primary rounded-full">
                                    <span class="text-white -mt-0.5 text-xs">1</span>
                                </span>
                            </span>
                            <span class="mt-0.5 ml-1.5 min-w-0 flex flex-col">
                                <span class="font-medium text-xs leading-5 -mt-1 text-gray-300">Select a Template to copy</span>
                                <span class="text-gray-900 text-xs tracking-tight">Just looking? <a class="text-primary underline" href="https://stg.startdeck.com/template-library/startdeck-demo-doc">Try the Demo Doc</a></span>
                            </span>
                        </span>
                    </div>
                </div>
            </li>
    
            <li class="relative overflow-hidden">
                <div class="border border-primary border-opacity-50 overflow-hidden">
                    <div aria-current="step">
                    <span class="px-2 py-2 pb-1.5 flex items-start">
                        <span class="flex-shrink-0">
                        <span class="w-6 h-6 flex items-center justify-center border-2 border-success bg-success rounded-full">
                            <span class="text-white -mt-0.5 text-xs">2</span>
                        </span>
                        </span>
                        <span class="mt-0.5 ml-1.5 min-w-0 flex flex-col">
                            <span class="font-medium text-xs leading-5 -mt-1 text-gray-300">Copy to your G Drive</span>
                            <span class="text-gray-900 text-xs tracking-tight">Sign-in and copy</span>
                        </span>
                    </span>
                    </div>
        
                    <div class="hidden absolute top-0 left-0 w-3 inset-0" aria-hidden="true">
                        <svg class="h-full w-full text-primary text-opacity-50" viewBox="0 0 12 82" fill="none" preserveAspectRatio="none">
                            <path d="M0.5 0V31L10.5 41L0.5 51V82" stroke="currentcolor" vector-effect="non-scaling-stroke" />
                        </svg>
                    </div>
                </div>
            </li>
    
            <li class="relative overflow-hidden">
                <div class="border border-primary border-opacity-50 overflow-hidden border-t-0 rounded-b-md">
                    <div class="group">
                        <span class="px-2 py-2 pb-1.5 flex items-start">
                            <span class="flex-shrink-0">
                                <span class="w-6 h-6 flex items-center justify-center border-2 border-warning bg-warning rounded-full">
                                    <span class="text-white -mt-0.5 text-xs">3</span>
                                </span>
                            </span>
                            <span class="mt-0.5 ml-1.5 min-w-0 flex flex-col">
                                <span class="font-medium text-xs leading-5 -mt-1 text-gray-300">Open in Google Docs</span>
                                <span class="text-gray-900 text-xs tracking-tight">Customize or Use As Is</span>
                            </span>
                        </span>
                    </div>
        
                    <div class="hidden absolute top-0 left-0 w-3 inset-0" aria-hidden="true">
                        <svg class="h-full w-full text-primary text-opacity-50" viewBox="0 0 12 82" fill="none" preserveAspectRatio="none">
                            <path d="M0.5 0V31L10.5 41L0.5 51V82" stroke="currentcolor" vector-effect="non-scaling-stroke" />
                        </svg>
                    </div>
                </div>
            </li>
        </ol>
    </nav>
</div>