import {ErrorHandler, Inject, Injectable, PLATFORM_ID} from '@angular/core';

@Injectable()
export class AppErrorHandler implements ErrorHandler {

  constructor(@Inject(PLATFORM_ID) private platformId: any) {
  }

  handleError(error: any): void {
    console.error(error);
    // if(isPlatformBrowser(this.platformId)) {
    //   this.handleBrowser(error);
    // } else {
    //   this.handleServer(error);
    // }
  }

  handleBrowser(error: any): void {
   console.error(error);
  }

  handleServer(error: any): void {
    console.error(error);
  }
}