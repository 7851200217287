<div class="modal in p-4" id="modal-tag-description">
    <div class="modal-dialog max-w-2xl animated-from-top">
        <div class="modal-header px-4 py-2.5 bg-primary text-white relative flex justify-between">
            <span class="text-sm font-medium">Tags and Description</span>
            <i class="fa fa-times leading-6 opacity-50 cursor-pointer modalclose" (click)="close()"></i>
        </div>
        <div class="modal-body px-4 py-3">
            <!-- Description -->
            <div class="border-b border-light pb-3 mb-3">
                <h4 class="mb-2 font-medium text-gray-300">Description</h4>
                <p class="text-gray-600 text-sm">
                    {{ data.template ? data.template.description : '-' }}
                </p>
            </div>
            <!-- Tags -->
            <div>
                <h4 class="mb-2 font-medium text-gray-300">Tags</h4>
                <ul class="template-tags text-xs">
                    <li class="tag" *ngFor="let tag of data.template.tags"> {{tag}}</li>
                </ul>
            </div>
        </div>
    </div>
</div>