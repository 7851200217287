import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TemplateService } from '../../template.service';
import { HelperService, AuthService, MetaService } from '@app/core'
import { UpgradePlanComponent } from '@app/shared/components/modal/upgrade-plan/upgrade-plan.component';

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
})
export class PreviewComponent implements OnInit {

  template: any = null;
  permissions: any = [];
  activeTab: string = '';
  openPremiumTemplateAccuontUpgradePopup: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private templateService: TemplateService,
    private helperService: HelperService,
    public authService: AuthService,
    private metaService : MetaService
  ) {
    this.getTemplateBySlug(this.route.snapshot.params['slug']);
  }

  ngOnInit(): void {
  }

  getTemplateBySlug(slug: string) {
    this.templateService.findBySlug(slug).subscribe((response: any) => {
      if (response && response.error == false) {
        this.template = response.template;
        this.permissions = response.permissions
        this.activeTab = this.template.resources[0].mimeType;
        this.metaService.updateTitle(this.template.page_title + ' | Template Library | StartDeck');
        this.metaService.updateMetaDescription(this.template.seo_description);
        this.metaService.updateKeywords(this.template.seo_keywords);
      } else {
        this.helperService.navigateToUrl('template-library');
        this.helperService.errorMessage(response.message);
      }
    });
  }

  handleTabVisibility(mimetype: string) {
    this.activeTab = mimetype;
  }

  copyTemplate() {
    if (this.authService.isLoggedIn) {
      this.doCopy();
      return;
    }

    if (!this.authService.isLoggedIn) {
      if (this.template.is_premium) {
        this.helperService.navigateToUrl('/login?action=copy_premium_template&data=' + this.template.uid);
      } else {
        this.helperService.navigateToUrl('/login?action=copy_template&data=' + this.template.uid);
      }
      return;
    }
  }

  doCopy() {

    this.helperService.blockUI('Creating copy, this may take a minute ...');

    this.templateService.copy(this.template.uid).subscribe((response: any) => {

      if (response && response.error == true) {
        if (response.error_type == 'UPGRADE_PLAN') {
          this.helperService.openModal(UpgradePlanComponent)
          this.helperService.unBlockUI();
        }
        else if (response.error_type == 'PREMIUM_TEMPLATE') {
          this.openPremiumTemplateAccuontUpgradePopup = true;
          this.helperService.unBlockUI();
        } else {
          this.helperService.unBlockUI();
          this.helperService.errorMessage(response.message);
        }
      } else {
        this.helperService.navigateToUrl('user-templates?'+'copy=true&uid='+response.userTemplateUid);
        this.helperService.unBlockUI();
      }
    });
  }
}
