<header class="main-navigation bg-white fixed top-0 left-0 right-0 w-full z-1000"
    [ngClass]="authService.user ? 'md:shadow-header md:bg-navigation' : 'sm:shadow-header sm:bg-navigation'">
    <div class="md:container mx-auto">
        <div class="flex flex-wrap items-center">
            <div class="flex-1 z-0 pl-4 py-2 sm:py-4"
                [ngClass]="authService.user ? 'shadow-header md:shadow-none' : 'shadow-header sm:shadow-none'">
                <div class="flex flex-wrap">
                    <div class="flex-1">
                        <a class="inline-block" [routerLink]="'/'" title="StartDeck">
                            <!-- <picture class="inline-block">
                                <source media="(max-width: 639px)" 
                                    srcset="assets/images/logo/startdeck-logo-sm.png, assets/images/logo/startdeck-logo-sm@2x.png 2x, assets/images/logo/startdeck-logo-sm@3x.png 3x">
                                <img class="inline-block" 
                                    [src]="logo.src" 
                                    [srcset]="logo.srcset"
                                    alt="StartDeck">
                            </picture> -->

                            <picture class="inline-block">
                                <source media="(max-width: 639px)" srcset="assets/images/logo/startdeck-icon.svg">
                                <img class="inline-block" [src]="logo.src" [alt]="logo.alt">
                            </picture>
                        </a>
                    </div>
                    <img src="assets/images/hamburger-close.svg" *ngIf="!isMobileDropdownOpen"
                        (click)="hamburgerMenuNavigationBarHandler()"
                        class="hamburger-menu-toggle pointer-cursor inline-flex mr-4"
                        [ngClass]="authService.user ? 'md:hidden' : 'sm:hidden'">

                    <img src="assets/images/hamburger-menu.svg" *ngIf="isMobileDropdownOpen"
                        (click)="hamburgerMenuNavigationBarHandler()"
                        class="hamburger-menu-toggle pointer-cursor inline-flex mr-4"
                        [ngClass]="authService.user ? 'md:hidden' : 'sm:hidden'">
                </div>
            </div>

            <!-- 
                #########################################################################################
                ## Large Screen Menu Bar ##
                #########################################################################################
            -->
            <div class="hidden w-auto pb-0 relative" [ngClass]="authService.user ? 'md:flex' : 'sm:flex'">
                <ul class="menu-bar flex items-center px-4 justify-between text-sm text-gray-300">

                    <!-- About Startdeck -->
                    <li class="menu-item px-3 lg:px-4" *ngIf="!authService.user">
                        <a class="block py-2 lg:hover:text-primary" [routerLink]="'/p/about-startdeck'">
                            About
                        </a>
                    </li>

                    <!-- Pricing -->
                    <li class="menu-item px-3 lg:px-4" *ngIf="!authService.user"
                        [ngClass]="checkIfUrlMatch(['pricing']) ? 'active' : ''">
                        <a class="block py-2 lg:hover:text-primary" [routerLink]="'/pricing'">
                            Pricing & Plans
                        </a>
                    </li>

                    <!-- StartDeck Template Library -->
                    <li class="menu-item pl-3 pr-3 lg:pr-4" routerLinkActive="active">
                        <a class="block py-2 lg:hover:text-primary" [routerLink]="'/template-library'">
                            <div class="badge-circle mr-1 hidden lg:inline-block"
                                *ngIf="checkIfUrlMatch(['get-started', 'get-started'])">2</div>
                            Template Library
                        </a>
                    </li>

                    <!-- Startdeck Blog -->
                    <li class="menu-item px-3 lg:px-4" *ngIf="!authService.user">
                        <a class="block py-2 lg:hover:text-primary"
                        href="https://blog.startdeck.com/commercial-real-estate-appraisal-a-ten-point-guide-to-cre-valuation/">
                            Blog
                        </a>
                    </li>

                    <!-- User | Team Templates -->
                    <li class="menu-item px-3 lg:px-4" [ngClass]="checkIfUrlMatch(['user-templates']) ? 'active' : ''"
                        *ngIf="authService.user" routerLinkActive="current">
                        <a class="block py-2 lg:hover:text-primary" *ngIf="authService.user.default_team"
                            title="Team Templates" [routerLink]="'/user-templates'">
                            Team Templates
                        </a>
                        <a class="block py-2 lg:hover:text-primary" *ngIf="!authService.user.default_team"
                            title="My Templates" [routerLink]="'/user-templates'">
                            My Templates
                        </a>
                    </li>

                    <!-- Projects | Team Projects -->
                    <li class="menu-item px-3 lg:px-4" [ngClass]="checkIfUrlMatch(['projects']) ? 'active' : ''"
                        *ngIf="authService.user" routerLinkActive="current">
                        <a class="block py-2 lg:hover:text-primary" *ngIf="authService.user.default_team"
                            title="Team Projects" [routerLink]="'/projects'">
                            Team Projects
                        </a>
                        <a class="block py-2 lg:hover:text-primary" *ngIf="!authService.user.default_team"
                            title="My Projects" [routerLink]="'/projects'">
                            My Projects
                        </a>
                    </li>

                    <!-- Knowledge Base -->
                    <li class="menu-item px-3 lg:px-4">
                        <a class="block py-2 lg:hover:text-primary" [routerLink]="'/knowledge-base'"
                            title="Knowledge Base">
                            Knowledge Base
                        </a>
                    </li>


                    <!-- Start Login User Dropdown -->
                    <li class="pl-2 lg:pl-4" *ngIf="authService.user">
                        <a href="javascript:void(0);"
                            class="border-2 border-transparent hover:border-gray-light transition duration-300 inline-block rounded-full login-dropdown-toggle-action"
                            (click)="largeScreenNavigationMenutoggler()">
                            <img [src]="authService.user.profile_image_url" class="inline-block rounded-full w-10 h-10">
                        </a>
                        <ul class="bg-white list-none shadow-dropdown absolute rounded z-10 mt-2 min-w-max-content w-56 hidden login-dropdown-menu"
                            style="right: 16px;">
                            <!-- User Information -->
                            <li>
                                <div class="bg-primary text-white px-3 py-2 clearfix rounded-t">
                                    <h4 class="font-medium tracking-wide text-lg">{{ authService.user.name }}</h4>
                                    <small class="leading-4 font-normal">
                                        <i class="fa fa-envelope"></i> {{ authService.user.email }}
                                    </small>
                                </div>
                            </li>

                            <!-- Vendor -->
                            <li class="border-b border-light">
                                <a class="block px-3 py-3 hover:bg-smoke-white transition duration-300"
                                    href="javascript:void(0);" [routerLink]="'/vendors'">
                                    Vendors
                                </a>
                            </li>

                            <!-- Team Docs -->
                            <li class="border-b border-light"
                                *ngIf="!this.authService.isLoggedIn || (this.authService.user.default_team == null && !this.authService.user.hasTeamDocsSubscription) || (this.authService.user.default_team == null && this.authService.isLoggedIn && !this.authService.user.hasTeamDocsSubscription)">
                                <a class="block px-3 py-3 hover:bg-smoke-white transition duration-300"
                                    href="javascript:void(0);" [routerLink]="'/team-docs'">
                                    Team Docs
                                </a>
                            </li>

                            <!-- Track Documents -->
                            <li class="border-b border-light">
                                <a class="block px-3 py-3 hover:bg-smoke-white transition duration-300"
                                    href="javascript:void(0);" [routerLink]="'/track-documents'">
                                    Track Documents
                                </a>
                            </li>

                            <!-- Manage Teams (Team Not selected) -->
                            <li class="border-b border-light relative"
                                *ngIf="authService.user && (authService.user.teams.length > 0 || authService.user.hasTeamDocsSubscription) && !authService.user.default_team">
                                <a href="javascript:void(0);"
                                    class="manage-team-action login-dropdown-toggle-action duration-300 flex hover:bg-smoke-white items-center justify-between px-3 py-3 transition"
                                    (click)="manageTeamToggler()">
                                    Manage Teams <i class="fa fa-caret-down text-base ml-2" aria-hidden="true"></i>
                                </a>
                                <ul
                                    class="bg-white list-none shadow-dropdown rounded min-w-max-content text-xs hidden manage-team-menu">

                                    <li class="border-b border-light p-2">
                                        <a class="bg-light flex items-center justify-center focus:outline-none rounded text-center text-primary tracking-wide p-1"
                                            [routerLink]="'/team-docs/teams'">
                                            <i class="fa fa-cog text-sm mr-1"></i> Manage Teams
                                        </a>
                                    </li>

                                    <li class="border-t border-light" *ngFor="let team of authService.user.teams">
                                        <a *ngIf="team.has_pending_invitation && !team.has_subscription_expired"
                                            class="flex items-center justify-between font-medium px-2.5 py-2 hover:bg-smoke-white transition duration-300"
                                            href="javascript:void(0);" [routerLink]="'/team-docs/teams/' + team.uid">
                                            <span class="flex items-center">
                                                <img class="w-6 h-6 object-cover rounded mr-1.5"
                                                    [src]="team.profile_image">{{ team.name }}
                                            </span>
                                            <img class="inline-block w-4 h-4 ml-2" src="assets/images/icon-pending.svg">
                                        </a>

                                        <a *ngIf="!team.has_pending_invitation"
                                            class="flex items-center font-medium px-2.5 py-2 hover:bg-smoke-white transition duration-300"
                                            href="javascript:void(0);" (click)="setDefaultTeam(team)">
                                            <img class="w-6 h-6 object-cover rounded mr-1.5"
                                                [src]="team.profile_image">{{ team.name }}
                                        </a>
                                    </li>

                                </ul>
                            </li>

                            <!-- Manage Teams (Team selected) -->
                            <li class="border-b border-light relative"
                                *ngIf="authService.user && (authService.user.teams.length > 0 || authService.user.hasTeamDocsSubscription) && authService.user.default_team">
                                <a href="javascript:void(0);"
                                    class="manage-team-action login-dropdown-toggle-action duration-300 flex hover:bg-smoke-white items-center justify-between px-3 py-3 transition"
                                    (click)="manageTeamToggler()" [title]="authService.user.default_team.name">
                                    {{ helperService.truncateString(authService.user.default_team.name,15) }}
                                    <i class="fa fa-caret-down text-base ml-2" aria-hidden="true"></i>
                                </a>
                                <ul class="bg-white list-none shadow-dropdown rounded min-w-max-content text-xs hidden manage-team-menu">

                                    <li class="border-b border-light p-2 flex flex-col gap-2">
                                        <a class="bg-light flex items-center justify-center focus:outline-none rounded text-center text-primary tracking-wide px-2.5 py-1"
                                            [routerLink]="'/team-docs/teams'">
                                            <i class="fa fa-cog text-sm mr-1"></i> Manage Teams
                                        </a>

                                        <a class="bg-light flex items-center justify-center focus:outline-none rounded text-center text-primary tracking-wide px-2.5 py-1"
                                            href="javascript:void(0);" (click)="setMyDrive()">
                                            <img class="inline-block w-4 h-4 mr-1"
                                                src="assets/images/icon-google-drive.svg">
                                            Show My Files
                                        </a>
                                    </li>

                                    <li class="border-t border-light" *ngFor="let team of authService.user.teams">
                                        <a *ngIf="team.has_pending_invitation"
                                            class="flex items-center justify-between font-medium px-2.5 py-2 hover:bg-smoke-white transition duration-300"
                                            href="javascript:void(0);" [routerLink]="'/team-docs/teams/' + team.uid">
                                            <span class="flex items-center">
                                                <img class="w-6 h-6 object-cover rounded mr-1.5"
                                                    [src]="team.profile_image">{{ team.name }}
                                            </span>
                                            <img class="inline-block w-3.5 h-3.5 ml-2"
                                                src="assets/images/icon-pending.svg">
                                        </a>

                                        <a *ngIf="!team.has_pending_invitation"
                                            class="flex items-center justify-between font-medium px-2.5 py-2 hover:bg-smoke-white transition duration-300"
                                            [ngClass]="{'bg-success bg-opacity-10 pointer-events-none' : team.uid == authService.user.default_team.uid}"
                                            href="javascript:void(0);" (click)="setDefaultTeam(team)">
                                            <span class="flex items-center">
                                                <img class="w-6 h-6 object-cover rounded mr-1.5"
                                                    [src]="team.profile_image">{{ team.name }}
                                            </span>
                                            <img *ngIf="team.uid == authService.user.default_team.uid"
                                                class="inline-block w-3.5 h-3.5 ml-2"
                                                src="assets/images/icon-check-success.svg">
                                        </a>
                                    </li>
                                </ul>
                            </li>

                            <!-- Subscription -->
                            <li class="border-b border-light" *ngIf="authService.user.subscription">
                                <a class="block px-3 py-3 hover:bg-smoke-white transition duration-300"
                                    href="javascript:void(0);" [routerLink]="'/subscription'">
                                    Subscription
                                </a>
                            </li>

                            <li class="border-b border-light" *ngIf="authService.user">
                                <a class="block px-3 py-3 hover:bg-smoke-white transition duration-300"
                                    href="javascript:void(0);" [routerLink]="'/pricing'">
                                    Pricing & Plans
                                </a>
                            </li>

                            <li class="border-b border-light" *ngIf="authService.user">
                                <a class="block px-3 py-3 hover:bg-smoke-white transition duration-300"
                                    href="https://blog.startdeck.com/commercial-real-estate-appraisal-a-ten-point-guide-to-cre-valuation/">
                                    Blog
                                </a>
                            </li>

                            <!-- Google Workspace Add-Ons -->
                            <li class="border-b border-light" *ngIf="authService.user">
                                <a class="block px-3 py-3 hover:bg-smoke-white transition duration-300"
                                    href="javascript:void(0);" [routerLink]="'/GSuite-Add-ons'">
                                    Google Workspace Add-Ons
                                </a>
                            </li>

                            <!-- Settings -->
                            <li class="border-b border-light">
                                <a class="block px-3 py-3 hover:bg-smoke-white transition duration-300"
                                    href="javascript:void(0);" routerLink="/settings">
                                    Settings
                                </a>
                            </li>

                            <!-- Logout -->
                            <li>
                                <a class="block px-3 py-3 hover:bg-smoke-white transition duration-300"
                                    [routerLink]="'/logout'">
                                    Logout
                                </a>
                            </li>
                        </ul>
                    </li>
                    <!-- End Login User Dropdown -->

                    <!-- Login -->
                    <li class="menu-item pl-3 lg:pl-4" routerLinkActive="active" *ngIf="!authService.user">
                        <a class="block py-2 lg:hover:text-primary" [routerLink]="'/login'">
                            <i class="fa fa-lock"></i> Login
                        </a>
                    </li>
                </ul>
            </div>


            <!-- 
                #########################################################################################
                ## Small Screen Menu Bar ##
                #########################################################################################
            -->
            <div class="w-full bg-body sm:bg-navigation small-screen-navigation-menu-items shadow-header login-dropdown-toggle-action"
                *ngIf="isMobileDropdownOpen">
                <!-- User Information -->
                <div class="bg-primary text-white px-4 py-3 clearfix" *ngIf="authService.user"
                    [ngClass]="authService.user ? 'md:hidden' : 'sm:hidden'">
                    <img [src]="authService.user.profile_image_url"
                        class="border-2 border-gray-light rounded-full float-left mr-3 w-10 h-10">
                    <h3 class="font-bold text-lg leading-5">{{ authService.user.name }}</h3>
                    <small class="leading-4 font-normal">
                        <i class="fa fa-envelope"></i> {{ authService.user.email }}
                    </small>
                </div>

                <ul class="items-center px-4 justify-between text-sm leading-6 text-gray-300 overflow-x-auto"
                    style="max-height: calc(100vh - 16rem); min-height: 10rem;"
                    [ngClass]="authService.user ? 'md:hidden md:overscroll-none' : 'sm:hidden sm:overscroll-none'">

                    <!-- About Startdeck -->
                    <li class="border-b border-light" *ngIf="!authService.user">
                        <a class="block py-3" [routerLink]="'/p/about-startdeck'">
                            <img src="assets/images/icon/info-circle.svg" class="inline align-text-bottom lg:hidden">
                            About
                        </a>
                    </li>

                    <!-- Pricing -->
                    <li class="border-b border-light" *ngIf="!authService.user">
                        <a class="block py-3" [routerLink]="'/pricing'">
                            <img src="assets/images/icon/price-tag.svg" class="inline align-text-bottom lg:hidden">
                            Pricing & Plans
                        </a>
                    </li>


                    <!-- StartDeck Template Library -->
                    <li class="border-b border-light">
                        <a class="block py-3" [routerLink]="'/template-library'">
                            <img src="assets/images/icon/template-library.svg"
                                class="inline align-text-bottom lg:hidden"> Template Library
                        </a>
                    </li>

                    <!-- User Templates -->
                    <li class="border-b border-light" *ngIf="authService.user" routerLinkActive="current">
                        <a class="block py-3" *ngIf="authService.user.default_team" title="Team Templates"
                            [routerLink]="'/user-templates'">
                            <img src="assets/images/icon/my-templates.svg" class="inline align-text-bottom lg:hidden">
                            Team Templates
                        </a>
                        <a class="block py-3" *ngIf="!authService.user.default_team" title="My Templates"
                            [routerLink]="'/user-templates'">
                            <img src="assets/images/icon/my-templates.svg" class="inline align-text-bottom lg:hidden">
                            My Templates
                        </a>
                    </li>

                    <!-- Projects -->
                    <li class="border-b border-light" *ngIf="authService.user" routerLinkActive="current">
                        <a class="block py-3" *ngIf="authService.user.default_team" title="Team Projects"
                            [routerLink]="'/projects'">
                            <img src="assets/images/icon/my-projects.svg" class="inline align-text-bottom lg:hidden">
                            Team Projects
                        </a>
                        <a class="block py-3" *ngIf="!authService.user.default_team" title="My Projects"
                            [routerLink]="'/projects'">
                            <img src="assets/images/icon/my-projects.svg" class="inline align-text-bottom lg:hidden">
                            My Projects
                        </a>
                    </li>

                    <!-- Team Docs -->
                    <!-- <li class="border-b border-light"
                        *ngIf="!this.authService.isLoggedIn || (this.authService.user.default_team == null && !this.authService.user.hasTeamDocsSubscription) || (this.authService.user.default_team == null && this.authService.isLoggedIn && !this.authService.user.hasTeamDocsSubscription) ">
                        <a class="block py-3 lg:hover:text-primary" [routerLink]="'/team-docs'">
                            <img src="assets/images/icon/team-docs.svg" class="inline align-text-bottom lg:hidden"> Team
                            Docs
                        </a>
                    </li> -->



                    <!-- Knowledge Base -->
                    <li class="border-b border-light">
                        <a class="block py-3" [routerLink]="'/knowledge-base'">
                            <img src="assets/images/icon/knowledge-base.svg" class="inline align-text-bottom lg:hidden">
                            Knowledge Base
                        </a>
                    </li>

                    <!-- Vendor -->
                    <li class="border-b border-light" *ngIf="authService.user">
                        <a class="block py-3" href="javascript:void(0);" [routerLink]="'/vendors'">
                            <img src="assets/images/icon/vendor.svg" class="inline align-text-bottom lg:hidden">
                            Vendors
                        </a>
                    </li>

                    <!-- Track Documents -->
                    <li class="border-b border-light" *ngIf="authService.user">
                        <a class="block py-3" href="javascript:void(0);" [routerLink]="'/track-documents'">
                            <img src="assets/images/icon/tracking.svg" class="inline align-text-bottom lg:hidden"> Track
                            Documents
                        </a>
                    </li>


                    <!-- Manage Teams (Team Not selected) -->
                    <li class="border-b border-light"
                        *ngIf="authService.user && (authService.user.teams.length > 0 || authService.user.hasTeamDocsSubscription) && !authService.user.default_team">
                        <a href="javascript:void(0);" (click)="manageTeamToggler()"
                            class="manage-team-action login-dropdown-toggle-action flex items-center justify-between py-3 lg:hover:text-primary">
                            <span>
                                <img src="assets/images/icon/manage-teams.svg"
                                    class="inline align-text-bottom lg:hidden"> Manage Teams
                            </span>
                            <i class="fa fa-caret-down text-base mr-2" aria-hidden="true"></i>
                        </a>
                        <ul class="list-none bg-white bg-opacity-75 manage-team-menu">

                            <li class="border-t border-light p-2.5">
                                <a class="bg-light flex items-center justify-center focus:outline-none rounded text-center text-primary tracking-wide p-2"
                                    [routerLink]="'/team-docs/teams'">
                                    <i class="fa fa-cog text-sm mr-1"></i> Manage Teams
                                </a>
                            </li>

                            <li class="border-t border-light" *ngFor="let team of authService.user.teams">
                                <a *ngIf="team.has_pending_invitation" class="flex items-center justify-between p-2.5"
                                    href="javascript:void(0);" [routerLink]="'/team-docs/teams/' + team.uid">
                                    <span class="flex items-center">
                                        <img class="w-6 h-6 object-cover rounded mr-1.5" [src]="team.profile_image">{{
                                        team.name }}
                                    </span>
                                    <img class="inline-block w-3.5 h-3.5" src="assets/images/icon-pending.svg">
                                </a>

                                <a *ngIf="!team.has_pending_invitation" class="flex items-center p-2.5"
                                    (click)="setDefaultTeam(team)">
                                    <img class="w-6 h-6 object-cover rounded mr-1.5" [src]="team.profile_image">{{
                                    team.name }}
                                </a>
                            </li>

                        </ul>
                    </li>

                    <!-- Manage Teams (Team selected) -->
                    <li class="border-b border-light"
                        *ngIf="authService.user && (authService.user.teams.length > 0 || authService.user.hasTeamDocsSubscription) && authService.user.default_team">
                        <a href="javascript:void(0);" (click)="manageTeamToggler()"
                            class="manage-team-action login-dropdown-toggle-action flex items-center justify-between py-3 lg:hover:text-primary">
                            <span>
                                <img src="assets/images/icon/manage-teams.svg"
                                    class="inline align-text-bottom lg:hidden">
                                Manage Teams
                            </span>
                            <i class="fa fa-caret-down text-base mr-2" aria-hidden="true"></i>
                        </a>
                        <ul class="list-none bg-white bg-opacity-75 manage-team-menu">

                            <li class="border-t border-light p-2.5 flex">
                                <a class="bg-light flex w-6/12 items-center justify-center focus:outline-none rounded text-center text-primary tracking-wide p-2"
                                    [routerLink]="'/team-docs/teams'">
                                    <i class="fa fa-cog text-sm mr-1"></i> Manage Teams
                                </a>

                                <a class="bg-light flex w-6/12 items-center justify-center focus:outline-none rounded text-center text-primary tracking-wide p-2 ml-2.5"
                                    href="javascript:void(0);" (click)="setMyDrive()">
                                    <img class="inline-block w-4 h-4 mr-1" src="assets/images/icon-google-drive.svg">
                                    Show My Files
                                </a>
                            </li>

                            <li class="border-t border-light" *ngFor="let team of authService.user.teams">
                                <a *ngIf="team.has_pending_invitation"
                                    class="flex items-center justify-between p-2.5 transition duration-300"
                                    href="javascript:void(0);" [routerLink]="'/team-docs/teams/' + team.uid">
                                    <span class="flex items-center">
                                        <img class="w-6 h-6 object-cover rounded mr-1.5" [src]="team.profile_image">{{
                                        team.name }}
                                    </span>
                                    <img class="inline-block w-3.5 h-3.5" src="assets/images/icon-pending.svg">
                                </a>

                                <a *ngIf="!team.has_pending_invitation"
                                    class="flex items-center justify-between p-2.5 transition duration-300"
                                    href="javascript:void(0);"
                                    [ngClass]="{'bg-success bg-opacity-10 font-medium pointer-events-none' : team.uid == authService.user.default_team.uid}"
                                    (click)="setDefaultTeam(team)">
                                    <span class="flex items-center">
                                        <img class="w-6 h-6 object-cover rounded mr-1.5" [src]="team.profile_image">{{
                                        team.name }}
                                    </span>
                                    <img *ngIf="team.uid == authService.user.default_team.uid"
                                        class="inline-block w-3.5 h-3.5" src="assets/images/icon-check-success.svg">
                                </a>
                            </li>

                        </ul>
                    </li>


                    <!-- Subscription -->
                    <li class="border-b border-light" *ngIf="authService.user && authService.user.subscription">
                        <a class="block py-3" href="javascript:void(0);" [routerLink]="'/subscription'">
                            <img src="assets/images/icon/subscription.svg" class="inline align-text-bottom lg:hidden">
                            Subscription
                        </a>
                    </li>

                    <li class="border-b border-light" *ngIf="authService.user">
                        <a class="block py-3" href="javascript:void(0);" [routerLink]="'/pricing'">
                            <img src="assets/images/icon/price-tag.svg" class="inline align-text-bottom lg:hidden">
                            Pricing & Plans
                        </a>
                    </li>

                    <!-- Startdeck Blog -->
                    <li class="border-b border-light">
                        <a class="block py-3"
                            href="https://blog.startdeck.com/commercial-real-estate-appraisal-a-ten-point-guide-to-cre-valuation/">
                            <img src="assets/images/icon/blog.svg" class="inline align-text-bottom lg:hidden"> Blog
                        </a>
                    </li>

                    <!-- Google Workspace Add-Ons -->
                    <li class="border-b border-light" *ngIf="authService.user">
                        <a class="block py-3" href="javascript:void(0);" [routerLink]="'/GSuite-Add-ons'">
                            <img src="assets/images/icon/add-ons.svg" class="inline align-text-bottom lg:hidden"> Google
                            Workspace Add-Ons
                        </a>
                    </li>

                    <!-- Settings -->
                    <li class="border-b border-light" *ngIf="authService.user">
                        <a class="block py-3" href="javascript:void(0);" routerLink="/settings">
                            <img src="assets/images/icon/settings.svg" class="inline align-text-bottom lg:hidden">
                            Settings
                        </a>
                    </li>

                    <!-- Login -->
                    <li class="" *ngIf="!authService.user">
                        <a class="block py-3" [routerLink]="'/login'">
                            <img src="assets/images/icon/lock.svg" class="inline align-text-bottom lg:hidden">
                            Login
                        </a>
                    </li>

                    <!-- Logout -->
                    <li class="" *ngIf="authService.user">
                        <a class="block py-3" [routerLink]="'/logout'">
                            <i class="align-text-bottom fa fa-sign-out ml-0.5 text-xl"></i> Logout
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</header>
<div class="h-14 sm:h-18"></div>