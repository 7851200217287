import { NgModule } from '@angular/core';
import { CoreModule } from '@app/core/core.module';

import { TemplateLibraryComponent } from './template-library.component';
import { TemplateComponent } from './components/template/template.component';
import { TagsAndDescriptionModalComponent } from './modals/tags-and-description-modal/tags-and-description-modal.component';
import { OverviewComponent as TemplateOverview } from './components/overview/overview.component';
import { PreviewComponent as TemplatePreview } from './components/preview/preview.component';
import { CopyWorkflowComponent } from './components/common/copy-workflow/copy-workflow.component';
import { CopyWorkflowModalComponent } from './modals/copy-workflow-modal/copy-workflow-modal.component';
import { LandingSectionComponent } from './components/landing-section/landing-section.component';
import { TagsComponent } from './components/tags/tags.component';
import { SearchFilterTagsComponent as TemplateSearchFilterTagsComponent }  from './components/search-filter-tags/search-filter-tags.component';
import { PremiumTemplateUpgradeAccountComponent } from './modals/premium-template-upgrade-account/premium-template-upgrade-account.component';
import{ShareModalComponent} from  './modals/share-modal/share-modal.component'


@NgModule({
  declarations: [
    TemplateLibraryComponent,
    TemplateComponent,
    TagsAndDescriptionModalComponent,
    TemplateOverview,
    TemplatePreview,
    CopyWorkflowComponent,
    CopyWorkflowModalComponent,
    LandingSectionComponent,
    TagsComponent,
    TemplateSearchFilterTagsComponent,
    PremiumTemplateUpgradeAccountComponent,
    ShareModalComponent
  ],
  imports: [
    CoreModule,
    //TemplateLibraryRoutingModule,
  ]
})
export class TemplateLibraryModule { }
