<div class="container mx-auto px-4 py-4 min-h-90-vh lg:min-h-70-vh xl:min-h-screen mb-20">
    <h3 class="text-lg sm:text-2xl leading-8 text-gray-300 text-center mt-0 mb-4 sm:mt-4 sm:mb-8">Thanks for checking out StartDeck!</h3>
    <div class="flex flex-wrap">
        <div class="bg-white shadow-card p-4 lg:p-6 w-full lg:w-10/12 mx-auto">
            <div class="grid lg:grid-cols-2 gap-4">
                <div class="bg-smoke-white p-4 lg:px-6 lg:py-4">
                    <h4 class="text-sm sm:text-base mb-4 text-gray-300">Next step is to sign-in and select a template:</h4>
                    <ol class="text-left list-decimal text-gray-600 font-normal text-xs leading-5 pl-4 sm:font-medium sm:text-sm sm:leading-6">
                        <li class="mb-3 sm:mb-4">
                            <span class="hidden lg:inline">Sign in to Drive</span>
                            <a class="lg:hidden text-primary underline" href="javascript:void(0);">Sign in to StartDeck with Google</a>
                        </li>
                        <li>
                            Copy a template from the <span class="hidden lg:inline">Template Library</span>
                            <a class="lg:hidden text-primary underline" href="javascript:void(0);">Template Library</a>
                        </li>
                    </ol>
                </div>
                <div class="sm:p-4 text-center hidden lg:block">
                    <p class="text-gray-900 text-sm tracking-wider mb-2">StartDeck runs on Google Drive.</p>
                    <p class="text-gray-900 text-sm tracking-wider mb-4">Please log-in to access templates and projects.</p>
                    <a class="inline-block text-primary" (click)="redirectToGoogleLogin()" href="javascript:void(0);">
                        <div class="badge-circle inline-block">1</div>
                        <img class="inline-block shadow-btn m-1" src="assets/images/google-login-btn.svg" alt="Sign in to StartDeck with Google">
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>