<div class="modal in block md:hidden">
    <div class="modal-dialog animated-from-bottom max-h-screen overflow-auto">
        <div class="modal-header px-3 py-2 bg-primary text-white text-sm leading-6 relative flex justify-between">
            <span *ngIf="data && data.heading; else noHeading"> {{ data.heading }}</span>
            <ng-template #noHeading> Search By Tags </ng-template>
            <i class="fa fa-times leading-6 opacity-50 cursor-pointer modalclose" (click)="close(['All'])"></i>
        </div>
        <div class="modal-body p-2 pt-0">
            <ul class="text-gray-300 font-medium text-sm list-group" *ngIf="data && data.tags.length > 0"> 
                <li (click)="selectActiveTag(tag)" class="list-group-item p-2 border-b border-light" *ngFor="let tag of data.tags">
                    <label class="flex items-center">
                        <input class="mr-1.5" type="radio" [checked]="data.preSelectedTags[0] == tag" name="list_name" value="{{tag}}" />
                        {{tag}}
                    </label>
                </li>
            </ul>
            <button (click)="apply()"
                class="bg-success p-2 leading-6 text-white rounded text-sm tracking-wide w-full font-medium focus:outline-none hover:shadow-btn transition-all duration-300">
                Apply
            </button>
        </div>
    </div>
</div>