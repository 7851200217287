import { Component, OnInit } from '@angular/core';
import { Modal } from '@app/modal/models/modal';
import { ApiService, AuthService, HelperService } from '@app/core';

@Component({
  selector: 'app-copy-workflow-modal',
  templateUrl: './copy-workflow-modal.component.html'
})
export class CopyWorkflowModalComponent extends Modal implements OnInit {

  donotShowModalCheckbox: boolean = false;

  constructor(
    private _apiService: ApiService,
    private _authService: AuthService,
    private helperService: HelperService
  ) {
    super();
  }

  ngOnInit(): void { }

  setInputData(inputData: any) { }

  setShowModalInStorage() {
    if (this.donotShowModalCheckbox) {
      localStorage.setItem('CopyWorkflowModal', 'true');
    }

    if (this.donotShowModalCheckbox && this._authService.isLoggedIn) {
      var request = this._apiService.post('save_copy_workflow_modal_preference', { show_copy_workflow_modal: false });
      this.helperService.blockUI();
      request.subscribe((response: any) => this.helperService.unBlockUI());
    }

    this.close();
  }

}
