import { Component, ComponentRef, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute, Params } from "@angular/router";
import { ApiService, MetaService, HelperService } from '@app/core';

@Component({
  selector: 'app-dynamic',
  templateUrl: './dynamic.component.html',
})
export class DynamicComponent implements OnInit {

  page: any = null;
  fragmentEl : any = null;

  constructor(
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService,
    private metaService: MetaService,
    private helperService: HelperService,
    @Inject(PLATFORM_ID) private platformId: any
  ) { }

  ngOnInit(): void {
    let library = '/assets/js/ckeditor/ckeditor.js';

    if (document.querySelectorAll('[src="' + library + '"]').length == 0) {
      const script1 = document.createElement('script');
      script1.async = true;
      script1.src = library;
      document.head.appendChild(script1);
    }
    
    this.activatedRoute.params.subscribe((params: Params) => this.getPage(this.activatedRoute.snapshot.params.slug));
  }

  getPage(slug: string) {
    this.apiService.get('p/' + slug).subscribe((response) => {
      if (response.error) {
        this.helperService.navigateToUrl('/');
      } else {
        this.page = response.page;
        this.metaService.updateTitle(this.page.title);
        this.metaService.updateMetaDescription(this.page.seo_description);
        this.metaService.updateKeywords(this.page.seo_keywords);

        if (isPlatformBrowser(this.platformId)) {
          setTimeout(() => {
            if (this.fragmentEl) {
              var element: any = document.getElementById(this.fragmentEl);

              if (element != null) {
                window.scroll(0, element.parentElement.parentElement.offsetTop - 80);
              }
            }
          }, 100);
        }
      }
    });
  }
}
