import { Component, OnInit } from '@angular/core';
import { ApiService } from '@app/core/services/api.service';
import { DomSanitizer } from '@angular/platform-browser';



@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit {
  youtubeUrl:any='';
  shortName="startdeck-in-5-minutes";
  constructor(
    private apiService: ApiService,
    private sanitizer: DomSanitizer
  ) {}
   
  ngOnInit(): void {
    this.apiService.get('youtube-url/'+this.shortName).subscribe((response:any)=>{
      if(!response.error){
        this.youtubeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(response.url);
      }else{
        this.youtubeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(response.url);
      }
  })
  }

}
