import { NgModule } from '@angular/core';
import { HelperService } from '@app/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

/*
###############################################################################################################
# Guards #
#################################################################################################################*/

import { AuthGuard } from '@app/core/guards/auth.guard';
import { ForceAuthRefreshGuard } from '@app/core/guards/force-auth-refresh.guard';


/*
###############################################################################################################
# Static Pages #
#################################################################################################################*/

import { HomeComponent } from '@app/static-pages/home/home.component';
import { AuthComponent } from '@app/auth/auth.component';
import { ContactUsComponent } from '@app/static-pages/contact-us/contact-us.component';
import { ExternalRedirectsComponent } from '@app/static-pages/external-redirects/external-redirects.component';
import { LogoutComponent } from '@app/auth/logout.component';
import { GSuiteAddOnsComponent } from '@app/static-pages/g-suite-add-ons/g-suite-add-ons.component';
import { GetStartedComponent } from '@app/static-pages/get-started/get-started.component';
import { SubscriptionPriceComponent } from '@app/static-pages/subscription-price/subscription-price.component';

import { TemplateLibraryComponent } from '@app/modules/template-library/template-library.component';
import { OverviewComponent as TemplateOverview } from '@app/modules/template-library/components/overview/overview.component';
import { PreviewComponent as TemplatePreview } from '@app/modules/template-library/components/preview/preview.component';
import { DynamicComponent } from '@app/static-pages/dynamic/dynamic.component';

const routes: Routes = [

  /*
  ###############################################################################################################
  # Static Pages #
  #################################################################################################################*/
  {
    path: '', component: HomeComponent, pathMatch: 'full' as 'full',
    canActivate: [ForceAuthRefreshGuard],
    data: {
      title: "StartDeck",
      metaDescription: 'Build Great Technical Reports with Google Docs',
      logo: HelperService.getLogo(1)
    }
  },

  {
    path: 'p/:slug', component: DynamicComponent, pathMatch: 'full' as 'full',
    data: {
      title: "StartDeck",
      metaDescription: 'Build Great Technical Reports with Google Docs',
      logo: HelperService.getLogo(1)
    }
  },

  {
    path: 'contact-us', component: ContactUsComponent,
    data: {
      title: "Contact us | StartDeck",
      metaDescription: 'If you have a question or a comment, please call us on support@startdeck.com',
      logo: HelperService.getLogo(1)
    }
  },

  {
    path: 'auth-token/:auth_token', component: AuthComponent,
    data: {
      title: "StartDeck",
      metaDescription: 'Build Great Technical Reports with Google Docs',
      logo: HelperService.getLogo(1)
    }
  },

  // Donot delete. Check Component. Done it for SSR
  {
    path: 'auth-token', component: AuthComponent,
    data: {
      title: "StartDeck",
      metaDescription: 'Build Great Technical Reports with Google Docs',
      logo: HelperService.getLogo(1)
    }
  },

  {
    path: 'logout', component: LogoutComponent,
    data: {
      title: "StartDeck",
      metaDescription: 'Build Great Technical Reports with Google Docs',
      logo: HelperService.getLogo(1)
    }
  },

  {
    path: 'GSuite-Add-ons', component: GSuiteAddOnsComponent,
    data: {
      title: "Google Workspace Add Ons | StartDeck",
      metaDescription: 'Power up with Google Workspace automation',
      logo: HelperService.getLogo(1)
    }
  },

  {
    path: 'get-started', component: GetStartedComponent,
    data: {
      title: "Get Started | StartDeck",
      metaDescription: 'Thanks for checking out StartDeck!',
      logo: HelperService.getLogo(1)
    }
  },

  {
    path: 'pricing', component: SubscriptionPriceComponent,
    canActivate: [ForceAuthRefreshGuard],
    data: {
      title: "Pricing | StartDeck",
      metaDescription: 'StartDeck monthly subscription pricing.',
      logo: HelperService.getLogo(2)
    }
  },

  {
    path: 'login',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule),
    data: {
      title: "Login | StartDeck",
      metaDescription: 'Please log-in to access templates and projects.',
      logo: HelperService.getLogo(1)
    }
  },

  {
    path: 'support', component: ExternalRedirectsComponent,
    data: {
      title: "Support | StartDeck",
      metaDescription: 'If you have a question or a comment, please call us on support@startdeck.com',
      logo: HelperService.getLogo(1)
    }
  },

  {
    path: 'demo', component: ExternalRedirectsComponent,
    data: {
      title: "Demo | StartDeck",
      metaDescription: 'If you have a question or a comment, please call us on support@startdeck.com',
      logo: HelperService.getLogo(1)
    }
  },

  {
    path: 'onboard', component: ExternalRedirectsComponent,
    data: {
      title: "Onboard | StartDeck",
      metaDescription: 'If you have a question or a comment, please call us on support@startdeck.com',
      logo: HelperService.getLogo(1)
    }
  },

  /*
  ###############################################################################################################
  # Template Library #
  #################################################################################################################*/

  {
    path: 'template-library', component: TemplateLibraryComponent, pathMatch: 'full' as 'full',
    canActivate: [ForceAuthRefreshGuard],
    data: {
      title: "Template Library | StartDeck",
      metaDescription: 'StartDeck provides turn-key professional templates for everyday business use.',
      logo: HelperService.getLogo(2)
    }
  },
  
  {
    path: 'template-library/:slug', component: TemplateOverview,
    canActivate: [ForceAuthRefreshGuard],
    data: {
      logo: HelperService.getLogo(2)
    }
  },

  {
    path: 'template-library/:slug/preview', component: TemplatePreview,
    canActivate: [ForceAuthRefreshGuard],
    data: {
      logo: HelperService.getLogo(2)
    }
  },

  /*
 ###############################################################################################################
 # User Templates #
 #################################################################################################################*/

  {
    path: 'user-templates',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/user-templates/user-templates.module').then(m => m.UserTemplatesModule)
  },

  /*
 ###############################################################################################################
 # Knowledge base #
 #################################################################################################################*/

  {
    path: 'knowledge-base',
    canActivate: [ForceAuthRefreshGuard],
    loadChildren: () => import('./modules/knowledge-base/knowledge-base.module').then(m => m.KnowledgeBaseModule)
  },

  /*
###############################################################################################################
# Projects #
#################################################################################################################*/

  {
    path: 'projects',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/projects/projects.module').then(m => m.ProjectsModule)
  },

  /*
 ###############################################################################################################
 # Team Docs #
 #################################################################################################################*/

  {
    path: 'team-docs',
    canActivate: [ForceAuthRefreshGuard],
    loadChildren: () => import('./modules/team-docs/team-docs.module').then(m => m.TeamDocsModule)
  },

  /*
 ###############################################################################################################
 # Sign Document #
 #################################################################################################################*/

  {
    path: 'sign-document',
    loadChildren: () => import('./modules/sign-document/sign-document.module').then(m => m.SignDocumentModule)
  },

  /*
 ###############################################################################################################
 # Subscription #
 #################################################################################################################*/

  {
    path: 'subscription',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/subscription/subscription.module').then(m => m.SubscriptionModule)
  },

  /*
 ###############################################################################################################
 # Vendors #
 #################################################################################################################*/

  {
    path: 'vendors',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/vendors/vendors.module').then(m => m.VendorsModule)
  },

  /*
 ###############################################################################################################
 # Settings #
 #################################################################################################################*/

  {
    path: 'settings',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/settings/settings.module').then(m => m.SettingsModule)
  },

  /*
###############################################################################################################
# Track Documents #
#################################################################################################################*/

  {
    path: 'track-documents',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/track-documents/track-documents.module').then(m => m.TrackDocumentsModule)
  },

  {
    path: '**', component: TemplateLibraryComponent, data: {
      logo: HelperService.getLogo(1)
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking'
    // preloadingStrategy: PreloadAllModules
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
