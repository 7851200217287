import { Component, OnInit } from '@angular/core';
import { Modal } from '@app/modal/models/modal';
import { ApiService, HelperService } from '@app/core'

@Component({
  selector: 'app-upgrade-plan',
  templateUrl: './upgrade-plan.component.html',
})
export class UpgradePlanComponent extends Modal implements OnInit {

  data: any = {};

  free_limit = 3;

  constructor(public helperService: HelperService, private apiService: ApiService) {
    super()
  }

  ngOnInit() {
    this.helperService.blockUI();

    let request = this.apiService.get('freemium-account-monthly-use-limit-status');

    request.subscribe((res: any) => {
      this.data = res;
      this.helperService.unBlockUI();
    });
  }

  setInputData(inputData: any) {
    this.data = inputData;
  }

  calculateRemainingSDTemplates() {
    let count = this.free_limit - this.data.sd_template_copy_count;
    if(count > 1){
      return count + ' Templates Remaining';
    }else if(count == 1){
      return count + ' Template Remaining';
    }
    return 0 + ' Template Remaining';
  }

  calculateRemainingProjects() {
    let count = this.free_limit - this.data.user_template_copy_count;

    if(count > 1){
      return count + ' Projects Remaining';
    }else if(count == 1){
      return count + ' Project Remaining';
    }
    return 0 + ' Project Remaining';
  }

  calculateRemainingSignature() {
    let count = this.free_limit - this.data.signature_count;
    if(count > 1){
      return count + ' Signatures/Deliveries Remaining';
    }else if(count == 1){
      return count + ' Signature/Delivery Remaining';
    }
    return 0 + ' Signature/Delivery Remaining';
  }
}
