import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-copy-workflow',
  templateUrl: './copy-workflow.component.html'
})
export class CopyWorkflowComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
