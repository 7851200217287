import { Component, OnInit } from '@angular/core';
import { Modal } from '@app/modal/models/modal';

@Component({
  selector: 'app-filter-tags-modal',
  templateUrl: './filter-tags-modal.component.html'
})
export class FilterTagsModalComponent extends Modal implements OnInit {

  data: any;
  tags: Array<any> = []
  masterSelected: boolean = false;
  selectedTags: any;
  selectedTag:any = null;

  ngOnInit(): void {
  }

  setInputData(data: any): void {
    this.data = data;
    this.selectedTag = this.data.preSelectedTags[0];
  }

  selectActiveTag(tag){
    this.selectedTag = tag;
  }

  apply() {
    if(this.selectedTag){
      this.close([this.selectedTag]);
    }
  }
}
