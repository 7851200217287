<div class="bg-white">
    <div class="md:container mx-auto">
        <div class="flex sm:items-center px-4 py-3">
            <div class="pr-2">
                <a class="text-gray-300" href="javascript:void(0);" routerLink="/template-library">
                    <i class="fa fa-angle-left font-bold text-3xl"></i>
                </a>
            </div>
            <div class="text-gray-300 font-medium flex-1 text-center text-sm sm:text-base" *ngIf="data && data.template">
                {{ data.template.name }}
            </div>
        </div>
    </div>
</div>

<!-- Spinner -->
<div class="text-center py-4" *ngIf="!isProcessing">
    <i class="fa fa-spinner fa-spin fa-2x text-primary" aria-hidden="true"></i>
</div>

<div class="md:container mx-auto px-4 pt-4 min-h-80-vh lg:min-h-70-vh xl:min-h-screen">
    <div *ngIf="data.template && data.template.page_description"> 
        <!-- Donot remove ck-content class. All ckeditor styling is under this classname. -->
        <div class="ck-content clearfix ck-editor-style overflow-auto" [innerHtml]="data.template.page_description | safeHtml"></div>
    </div>

    <!-- Action Buttons -->
    <div class="fixed bottom-0 z-1000 p-2 w-full max-w-md" id="bottomFixedStop" style="left: 50%; transform: translateX(-50%);" *ngIf="data.template">
        <div class="flex justify-center bg-white p-2 gap-2 rounded border border-gray-white shadow-card">
            <!-- Preview template -->
            <a href="javascript:void(0);"
                class="inline-flex items-center justify-center whitespace-no-wrap p-2 border border-transparent shadow-btn text-xs sm:text-sm font-medium tracking-normal sm:tracking-wide rounded text-white bg-primary hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary w-full"
                [routerLink]="'/template-library/' + data.template.slug + '/preview'">
                <i class="fa fa-eye text-sm sm:text-base mr-1 hidden sm:inline-flex"></i> Preview Template
            </a>
            <!-- copy template -->
             <!-- If  not logged in -->
            <ng-container *ngIf="!authService.user">
                <a href="javascript:void(0);" (click)="copyTemplate()"
                class="inline-flex items-center justify-center p-2 border border-transparent shadow-btn text-xs sm:text-sm font-medium tracking-normal sm:tracking-wide rounded text-white bg-success hover:bg-success focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-succbg-success w-full">
                    <i class="fa fa-clone mr-1 hidden sm:inline-flex"></i> Copy Template
                </a>
            </ng-container>
          
            <!-- If  logged in -->
            <ng-container *ngIf="authService.user">
                <!-- Free/Individual Pro -->
                <ng-container *ngIf="!authService.user.default_team">
                    <a href="javascript:void(0);" (click)="copyTemplate()"
                    class="inline-flex items-center justify-center p-2 border border-transparent shadow-btn text-xs sm:text-sm font-medium tracking-normal sm:tracking-wide rounded text-white bg-success hover:bg-success focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-succbg-success w-full">
                        <i class="fa fa-clone mr-1 hidden sm:inline-flex"></i> Copy Template
                    </a>
                </ng-container>

                <!-- Team Docs -->
                <ng-container *ngIf="authService.user.default_team && data.permissions && data.permissions.COPY_SD_TEMPLATES">
                    <a href="javascript:void(0);" (click)="copyTemplate()"
                    class="inline-flex items-center justify-center p-2 border border-transparent shadow-btn text-xs sm:text-sm font-medium tracking-normal sm:tracking-wide rounded text-white bg-success hover:bg-success focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-succbg-success w-full">
                        <i class="fa fa-clone mr-1 hidden sm:inline-flex"></i> Copy Template
                    </a>
                </ng-container>
            </ng-container>
            <!-- share -->
            <div class="wrapper">
                <button class="share-button-bg bg-gray-900 h-full duration-300 flex flex-row focus:outline-none font-medium gap-2 hover:shadow-btn items-center pl-3 pr-4 py-1 rounded text-sm text-white tracking-wide transition-all" >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4 ">
                    <path fill-rule="evenodd" d="M15.75 4.5a3 3 0 11.825 2.066l-8.421 4.679a3.002 3.002 0 010 1.51l8.421 4.679a3 3 0 11-.729 1.31l-8.421-4.678a3 3 0 110-4.132l8.421-4.679a3 3 0 01-.096-.755z" clip-rule="evenodd" />
                    </svg>
                Share
            </button>
                <div id="tooltip" class=" tooltip " style="left: -148px;">
                    <div class="sharethis-inline-share-buttons" [attr.data-url]="this.dataTemplateUrl" [attr.data-title]="this.dataMailSubject" [attr.data-message]="this.dataMailMessage" [attr.data-email-subject]="this.dataMailSubject"></div>
                </div>
            </div>
            <!-- share -->
        </div>
    </div>
</div>

<div class="pt-4" id="bottomFixedStopArea"></div>

<!-- Premium Content Template Alert -->
<app-premium-template-upgrade-account *ngIf="openPremiumTemplateAccuontUpgradePopup"></app-premium-template-upgrade-account>
