import { Injectable} from '@angular/core';
import { ApiService } from '@app/core';


@Injectable({
  providedIn: 'root'
})
export class TemplateService {

  constructor(
    private apiService: ApiService,
  ) { }

  get(refresh_from_drive : any, slug:any) {
    refresh_from_drive = refresh_from_drive ? '?refresh_from_drive=true' : '';

    if(slug && refresh_from_drive){
      slug = '?refresh_from_drive=true&slug='+slug;
    }else if(refresh_from_drive && !slug){
      slug = '?refresh_from_drive=true';
    }else{
      slug = '?slug='+slug;
    }

    return this.apiService.get('templates' + slug);
  }

  findBySlug(slug : string) {
    return this.apiService.get('templates/' + slug);
  }

  copy(uid :string) {
    return this.apiService.post('templates/' + uid + '/copy');
  }
}
