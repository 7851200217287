import {
  Injectable,
  ComponentFactoryResolver,
  ComponentFactory,
  ApplicationRef,
  ComponentRef,
  Type
} from '@angular/core';

import { ModalContainerComponent } from './modal-container/modal-container.component';

import { Modal } from './models/modal';
import { ModalRef } from './models/modal-ref';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  private modalContainer!: HTMLElement;
  private modalContainerFactory!: ComponentFactory<ModalContainerComponent>;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef
  ) { }

  private setupModalContainerFactory(): void {
    this.modalContainerFactory = this.componentFactoryResolver.resolveComponentFactory(ModalContainerComponent);
  }

  private setupModalContainerDiv(): void {
    this.modalContainer = document.createElement('div');
    document.getElementsByTagName('body')[0].appendChild(this.modalContainer);
  }

  private initialSetup() {
    this.setupModalContainerFactory();
    this.setupModalContainerDiv();
  }

  open<T extends Modal>(component: Type<T>, inputData?: any): ModalRef {

    this.initialSetup();

    const modalContainerRef = this.appRef.bootstrap(this.modalContainerFactory, this.modalContainer);

    const modalComponentRef = modalContainerRef.instance.createModal(component);

    if (inputData) {
      modalComponentRef.instance.setInputData(inputData);
    }

    const modalRef = new ModalRef(modalContainerRef, modalComponentRef);

    return modalRef;
  }
}
