import { Component, OnInit } from '@angular/core';
import { Modal } from '@app/modal/models/modal';

@Component({
  selector: 'app-tags-and-description-modal',
  templateUrl: './tags-and-description-modal.component.html',
})
export class TagsAndDescriptionModalComponent extends Modal implements OnInit {

  data: any;

  ngOnInit(): void { }

  setInputData(inputData: any) {
    this.data = inputData;
  }

}
