import { ModalRef } from './modal-ref';

export abstract class Modal {

  modalInstance!: ModalRef;

  abstract setInputData(inputData: any): void;

  close(output?: any): void {
    this.modalInstance.close(output);
  }

  dismiss(output?: any): void {
    this.modalInstance.dismiss(output);
  }

}