import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, APP_ID } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from '@app/core/core.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './static-pages/home/home.component';
import { NavComponent } from './layout/nav/nav.component';
import { FooterComponent } from './layout/footer/footer.component';
import { TemplateLibraryModule } from '@app/modules/template-library/template-library.module';
import { ContactUsComponent } from './static-pages/contact-us/contact-us.component';
import { AuthComponent } from './auth/auth.component';
import { LogoutComponent } from './auth/logout.component';
import { GSuiteAddOnsComponent } from './static-pages/g-suite-add-ons/g-suite-add-ons.component';
import { GetStartedComponent } from './static-pages/get-started/get-started.component';
import { CommonModule } from "@angular/common";
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { DynamicComponent } from './static-pages/dynamic/dynamic.component';
import { SubscriptionPriceComponent } from './static-pages/subscription-price/subscription-price.component';
import { TransferHttpCacheModule } from '@nguniversal/common';
import Bugsnag from '@bugsnag/js';
import {AppErrorHandler} from './app-error-handler';
// import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';


if (environment.production) {
  Bugsnag.start({ apiKey: environment.bugsnag_api_key });
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NavComponent,
    FooterComponent,
    ContactUsComponent,
    AuthComponent,
    LogoutComponent,
    GSuiteAddOnsComponent,
    GetStartedComponent,
    DynamicComponent,
    SubscriptionPriceComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule, // Donot move it to core
    CoreModule,
    TemplateLibraryModule,
    AppRoutingModule,
    CommonModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    TransferHttpCacheModule
  ],
  providers: [
    { provide: ErrorHandler, useClass: AppErrorHandler },
    { provide: APP_ID, useValue: 'serverApp' }
  ],
  bootstrap: [AppComponent],

})
export class AppModule { }
