<div *ngIf="page">
    <div class="bg-white">
        <div class="container mx-auto">
            <div class="flex sm:items-center px-4 py-3">
                <div class="pr-2">
                    <!-- <a class="text-gray-300" href="javascript:void(0);" (click)="back()">
                        <i class="fa fa-angle-left font-bold text-3xl"></i>
                    </a> -->
                </div>
                <div class="text-gray-300 font-medium flex-1 text-center text-sm sm:text-base">
                    {{page.title}}
                </div>
            </div>
        </div>
      </div>

    <div class="container mx-auto px-4 py-4 min-h-90-vh lg:min-h-70-vh xl:min-h-screen mb-20">
        <div class="static-pages-style bg-white p-4 shadow-card">
            <div class="ck-content clearfix ck-editor-style overflow-auto" *ngIf="page.bg_image" [style.background-image]="'url('+ page.bg_image +')'"
                [innerHtml]="page.content | safeHtml">
            </div>
            <div class="ck-content clearfix ck-editor-style overflow-auto" *ngIf="!page.bg_image"
                [innerHtml]="page.content | safeHtml">
            </div>
        </div>
    </div>
</div>