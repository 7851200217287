<div  class="fixed bottom-0 left-1/2 transform -translate-x-1/2 z-1000 p-2 sm:p-4 w-full max-w-3xl">
    <div class="rounded-md bg-yellow-100 border border-yellow-200 shadow-card px-3 sm:px-4 py-2 sm:py-3 leading-5 sm:leading-6">
        <div class="flex">
            <div class="flex-shrink-0 mt-px">
                <svg class="h-4 w-4 sm:h-5 sm:w-5 text-warning" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" fill="currentColor">
                    <path d="M576 136c0 22.09-17.91 40-40 40c-.248 0-.4551-.1266-.7031-.1305l-50.52 277.9C482 468.9 468.8 480 453.3 480H122.7c-15.46 0-28.72-11.06-31.48-26.27L40.71 175.9C40.46 175.9 40.25 176 39.1 176c-22.09 0-40-17.91-40-40S17.91 96 39.1 96s40 17.91 40 40c0 8.998-3.521 16.89-8.537 23.57l89.63 71.7c15.91 12.73 39.5 7.544 48.61-10.68l57.6-115.2C255.1 98.34 247.1 86.34 247.1 72C247.1 49.91 265.9 32 288 32s39.1 17.91 39.1 40c0 14.34-7.963 26.34-19.3 33.4l57.6 115.2c9.111 18.22 32.71 23.4 48.61 10.68l89.63-71.7C499.5 152.9 496 144.1 496 136C496 113.9 513.9 96 536 96S576 113.9 576 136z"/>
                </svg>
            </div>
            <div class="ml-2 sm:ml-3 flex-1 md:flex md:justify-between">
                <p class="text-xs sm:text-sm text-yellow-800">This template is premium content and requires a subscription.</p>
                <p class="mt-0.5 sm:mt-1 text-xs sm:text-sm md:mt-0 md:ml-6">
                    <a [routerLink]="'/pricing'" class="inline-flex items-center whitespace-nowrap font-medium text-yellow-600 hover:text-yellow-500">
                        See plans here <span class="text-sm sm:text-base ml-1" aria-hidden="true">&rarr;</span>
                    </a>
                </p>
            </div>
        </div>
    </div>
</div>