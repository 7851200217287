<!-- Footer -->
<footer class="bg-primary">
    <div class="md:container px-4 py-4 mx-auto">
        <ul class="text-sm text-white text-left sm:text-center pb-5">
            
            <li class="block sm:inline-block sm:px-3 md:px-6 py-2" *ngFor="let page of pages">
                <a [routerLink]="'/p/'+page.slug">{{ page.title }}</a>
            </li>

            <li class="block sm:inline-block sm:px-3 md:px-6 py-2"><a [routerLink]="'/contact-us'">Contact us</a></li>
            <li class="block sm:inline-block sm:px-3 md:px-6 py-2"><a [routerLink]="'/knowledge-base'">Knowledge Base</a></li>
            <li *ngFor='let assistant of assistants'class="block sm:inline-block sm:px-3 md:px-6 py-2"><a [routerLink]="'/knowledge-base/ai/'+assistant.identifire_key">{{assistant.title}}</a></li>

            <li *ngIf="authService.user" class="block sm:inline-block sm:px-3 md:px-6 py-2"><a href="https://blog.startdeck.com/commercial-real-estate-appraisal-a-ten-point-guide-to-cre-valuation/">Blog</a></li>
        </ul>
        <div class="bg-primary text-xxs py-2 text-center text-white leading-4">
            Copyright © 2024 StartDeck
        </div>
    </div>
</footer>