import { Injectable } from '@angular/core';
import { ApiService } from '@app/core';


@Injectable({
    providedIn: 'root'
})
export class TemplateLibraryService {

    constructor(
        private apiService: ApiService,
    ) { }

    getTags() {
        return this.apiService.get('template-library/landing-page/tags');
    }

    getDescription(slug: string) {
        return this.apiService.get('template-library/landing-page/' + slug + '/description');
    }

    sendMail(requestPayload: any){
        return this.apiService.post('template-library/send-mail',requestPayload);
    }
}
