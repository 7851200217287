import {
  Component,
  ViewChild,
  ViewContainerRef,
  ComponentFactoryResolver,
  ComponentFactory,
  Type,
  ComponentRef
} from '@angular/core';

import { Modal } from '../models/modal';

@Component({
  template: `<ng-template #modalContainer></ng-template>`
})
export class ModalContainerComponent {

  @ViewChild('modalContainer', { read: ViewContainerRef }) private modalContainer!: ViewContainerRef;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver
  ) { }

  createModal<T extends Modal>(component: Type<T>): ComponentRef<T> {

    this.modalContainer.clear();

    const factory: ComponentFactory<T> = this.componentFactoryResolver.resolveComponentFactory(component);

    return this.modalContainer.createComponent(factory, 0);
  }

}
