<div class="bg-white">
    <div class="md:container mx-auto">
        <div class="flex sm:items-center px-4 py-3" *ngIf="template">
            <div class="pr-2">
                <a class="text-gray-300" href="javascript:void(0);" [routerLink]="'/template-library/' + template.slug">
                    <i class="fa fa-angle-left font-bold text-3xl"></i>
                </a>
            </div>
            <div class="text-gray-300 font-medium flex-1 text-center text-sm sm:text-base">
                {{ template.name }}
            </div>
        </div>
    </div>
</div>

<div class="md:container mx-auto px-4 py-4 min-h-90-vh lg:min-h-70-vh xl:min-h-screen mb-20">
    <div class="flex flex-col-reverse sm:flex-row" *ngIf="template">
        <div class="w-full mt-4 sm:mt-0">
            <div class="bg-white shadow-card">
                <div class="flex border-b-2 border-gray-light text-center template-preview-tabs">
                    <ng-container *ngFor="let resource of template.resources">
                        <a *ngIf="resource.mimeType === 'application/vnd.google-apps.document'"
                            href="javascript:void(0);" class="tab-item w-full md:max-w-xxs"
                            (click)="handleTabVisibility(resource.mimeType)"
                            [ngClass]="{'template-preview-active' : activeTab == resource.mimeType}">
                            Template Preview
                        </a>

                        <a *ngIf="resource.mimeType === 'application/vnd.google-apps.spreadsheet'"
                            href="javascript:void(0);" class="tab-item w-full md:max-w-xxs"
                            (click)="handleTabVisibility(resource.mimeType)"
                            [ngClass]="{'table-preview-active' : activeTab == resource.mimeType}">
                            Table Preview
                        </a>
                    </ng-container>
                </div>
                <div class="relative sm:static">
                    <div class="bg-gold p-1.5 absolute top-0 left-0 right-0 w-full flex sm:hidden">
                        <svg class="w-4 h-4 text-gray-300 mt-0.5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
                        </svg>
                        <p class="ml-1 flex-1 leading-5 text-xs text-gray-300 tracking-wide">
                            For better preview experience use Desktop or Tablet
                        </p>
                    </div>
                    <ng-container *ngFor="let resource of template.resources">
                        <iframe *ngIf="activeTab==resource.mimeType" [src]="resource.preview_link | safeUrl" class="w-full min-h-screen"></iframe>
                    </ng-container>
                </div>
            </div>
        </div>

        <div class="w-full sm:max-w-xxs md:max-w-xs sm:pl-4 md:pl-8">
            <div class="bg-white shadow-card p-3.5 md:p-4">
                <ul class="text-left mb-4 bullet-primary text-gray-600 pl-4 font-medium text-xs md:text-sm sm:leading-5 md:leading-6">
                    <li class="mb-3 md:mb-4">Edit & customize this template in Google Docs!</li>
                    <li class="mb-3 md:mb-4">Format to match your brand</li>
                    <li class="mb-3 md:mb-4">Complete quickly with forms and merge fields</li>
                    <li class="mb-3 md:mb-4">Sign and get signed with eSignatures</li>
                    <li>Track delivery, view, and signs</li>
                </ul>
                
                 <!-- If  not logged in -->
                <ng-container *ngIf="!authService.user">
                    <a href="javascript:void(0);" (click)="copyTemplate()"
                    class="inline-flex items-center justify-center p-2 border border-transparent shadow-btn text-xs sm:text-sm font-medium tracking-normal sm:tracking-wide rounded text-white bg-success hover:bg-success focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-succbg-success w-full">
                        <i class="fa fa-clone mr-1 hidden sm:inline-flex"></i> Copy Template
                    </a>
                </ng-container>

                <!-- If  logged in -->
                <ng-container *ngIf="authService.user">
                    <!-- Free/Individual Pro -->
                    <ng-container *ngIf="!authService.user.default_team">
                        <a href="javascript:void(0);" (click)="copyTemplate()"
                        class="inline-flex items-center justify-center p-2 border border-transparent shadow-btn text-xs sm:text-sm font-medium tracking-normal sm:tracking-wide rounded text-white bg-success hover:bg-success focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-succbg-success w-full">
                            <i class="fa fa-clone mr-1 hidden sm:inline-flex"></i> Copy Template
                        </a>
                    </ng-container>

                    <!-- Team Docs -->
                    <ng-container *ngIf="authService.user.default_team && permissions && permissions.COPY_SD_TEMPLATES">
                        <a href="javascript:void(0);" (click)="copyTemplate()"
                        class="inline-flex items-center justify-center p-2 border border-transparent shadow-btn text-xs sm:text-sm font-medium tracking-normal sm:tracking-wide rounded text-white bg-success hover:bg-success focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-succbg-success w-full">
                            <i class="fa fa-clone mr-1 hidden sm:inline-flex"></i> Copy Template
                        </a>
                    </ng-container>
                </ng-container>
                
            </div>
        </div>
    </div>
</div>

<!-- Premium Content Template Alert -->
<app-premium-template-upgrade-account *ngIf="openPremiumTemplateAccuontUpgradePopup"></app-premium-template-upgrade-account>
