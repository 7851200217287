import { Output, EventEmitter, Component, OnInit, Input, Inject, PLATFORM_ID } from '@angular/core';
import { HelperService } from '@app/core';
import { TemplateLibraryService } from '../../template-library.service';
import { ActivatedRoute, Params } from "@angular/router";
import { find } from 'lodash';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';


@Component({
  selector: 'template-search-filter-tags',
  templateUrl: './search-filter-tags.component.html'
})
export class SearchFilterTagsComponent implements OnInit {

  @Output() outputSearchText = new EventEmitter<string>();
  searchText: string = ''; 

  @Output() selectedTag: EventEmitter<any> = new EventEmitter<any>();

  @Input() allTags: Array<any> = [];
  @Input() selectedTagUrl: any;
  activeTag: any = {};
  fullScreen = true;
  showDropDown = false;
  route: string | undefined = '';

  constructor(
    private helperService: HelperService,
    private templateLibraryService: TemplateLibraryService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: any,
  ) { }

  ngOnInit(): void {
    this.activeRoute.url.subscribe(url => {
      this.route = this.router.url.replace(/^\//, "");
      if (isPlatformBrowser(this.platformId)) {
        this.initTags();
      }
      if (this.helperService.isMobile()) this.fullScreen = false;
    });
  }

  changeSearchText(event) {
    this.outputSearchText.emit(this.searchText);
  }

  initTags(){
    this.activeRoute.params.subscribe((params: Params) => {
      this.showDropDown = false;
      this.activeTag = find(this.allTags, (t) => t.slug == this.route || t.url == this.selectedTagUrl);
      this.selectedTag.emit(this.activeTag);
    });
  }

  toggleTagsDropdown() {
    if (!this.showDropDown) {
      this.showDropDown = true;
      return;
    } else {
      this.showDropDown = false;
      return;
    }
  }

}
