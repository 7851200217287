import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule, CurrencyPipe, DecimalPipe, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { BlockUIModule } from 'ng-block-ui';
import { ToastrModule } from 'ngx-toastr';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown-ivy';

import { SearchByTagPipe } from '@app/core/pipes/search-by-tag.pipe';
import { SafeHtmlPipe } from '@app/core/pipes/safe-html.pipe';
import { SafeUrlPipe } from '@app/core/pipes/safe-url.pipe';

import { ModalModule } from '@app/modal/modal.module';
import { SharedModule } from '@app/shared/shared.module';

import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';

import {GuidedTourModule, GuidedTourService} from 'ngx-guided-tour';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    BlockUIModule.forRoot(),
    ModalModule.forRoot(),
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatDialogModule,
    GuidedTourModule,
  ],
  declarations: [
    SearchByTagPipe,
    SafeHtmlPipe,
    SafeUrlPipe
  ],
  exports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BlockUIModule,
    ToastrModule,
    ModalModule,
    SharedModule,
    SearchByTagPipe,
    SafeHtmlPipe,
    SafeUrlPipe,
    CurrencyPipe,
    DecimalPipe,
    AngularMultiSelectModule,
    MatChipsModule,
    MatFormFieldModule,
    MatIconModule,
    DragDropModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    GuidedTourModule,
  ],
  providers: [
    CurrencyPipe, DecimalPipe, DatePipe, MatNativeDateModule, GuidedTourService
  ]
})
export class CoreModule { }