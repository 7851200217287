<div class="border border-primary border-opacity-50 bg-white transform
    transition-all hover:border-opacity-100 hover:shadow-card group h-full flex
    flex-col justify-between relative">
    <div class="inline-flex flex-col">
        <div class="flex justify-between items-center px-4 py-3">
            <a href="javascript:void(0);" [routerLink]="'/template-library/' +
                template.slug" class="text-gray-300 group-hover:text-primary
                font-medium leading-6 truncate pr-2" title="{{ template.name
                }}">
                {{getHeadingText()}}
            </a>
            <!-- share -->
            <div class="flex gap-3 items-center justify-between" style="min-width: 60px;">

                <div id="boton" class="wrapper">
                    <a class="inline-block btn-open-modal" href="javascript:void(0);">
                        <img class="inline-block" src="assets/images/share1.svg"> 
                    </a>

                    <div id="tooltip" class=" tooltip z-1000" style="right:-75px;">
                        <div class="sharethis-inline-share-buttons " [attr.data-url]="this.dataTemplateUrl" [attr.data-title]="this.dataMailSubject" [attr.data-message]="this.dataMailMessage" [attr.data-email-subject]="this.dataMailSubject"></div>
                    </div>
                </div>

                <a class="inline-block btn-open-modal" href="javascript:void(0);" (click)="openTagAndDescriptionModal()">
                    <img class="inline-block" src="assets/images/icon-ellipsis-v.svg">
                </a>
            </div>
        </div>

        <a class="inline-flex w-full" style="box-shadow: 0px 1px 1px rgba(0, 0,
            0, 0.08);" href="javascript:void(0);" [routerLink]="'/template-library/' + template.slug">
            <img class="inline-block w-full object-cover object-top " style="max-height:
                264px;" *ngIf="template.screenshot_url" src="{{
                template.screenshot_url }}">
            <img class="inline-block w-full object-cover" style="max-height:
                264px;" *ngIf="!template.screenshot_url" src="assets/images/docs-preview-default.png">
        </a>

        <a href="javascript:void(0);" [routerLink]="'/template-library/' +
            template.slug" class="inline-flex flex-col items-center
            justify-center w-16 h-16 bg-light bg-opacity-75 border
            border-primary border-opacity-25 rounded-full absolute top-1/2
            left-1/2 transform -translate-x-1/2 -translate-y-1/2 transition-all
            duration-300 opacity-0 group-hover:opacity-100 -mt-8">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-primary" fill="none" viewBox="0 0 24 24"
                stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 21h7a2 2 0 002-2V9.414a1 1 0
                    00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2
                    2v11m0 5l4.879-4.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243
                    4.242z" />
            </svg>
            <span class="text-sm font-medium leading-4 text-primary mt-0.5">View</span>
        </a>
    </div>

    <div>
        <div class="pl-4 pr-8 py-3 pt-2 text-center relative">
            <p class="text-xs leading-6 text-gray-300 text-left" style="overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;">{{ template.description }}</p>
            <!-- Premium Badge -->
            <span *ngIf="template.is_premium" class="absolute bg-black
                    inline-flex items-center rounded-sm px-1.5 py-1
                    text-warning" style="bottom: 6px; right: 6px; font-size:
                    10px; background-color: rgb(0 0 0 / 75%);">
                <svg class="h-4 w-4 mb-px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" fill="currentColor">
                    <path d="M576 136c0 22.09-17.91 40-40 40c-.248
                            0-.4551-.1266-.7031-.1305l-50.52 277.9C482 468.9
                            468.8 480 453.3 480H122.7c-15.46
                            0-28.72-11.06-31.48-26.27L40.71 175.9C40.46 175.9
                            40.25 176 39.1 176c-22.09 0-40-17.91-40-40S17.91 96
                            39.1 96s40 17.91 40 40c0 8.998-3.521 16.89-8.537
                            23.57l89.63 71.7c15.91 12.73 39.5 7.544
                            48.61-10.68l57.6-115.2C255.1 98.34 247.1 86.34 247.1
                            72C247.1 49.91 265.9 32 288 32s39.1 17.91 39.1 40c0
                            14.34-7.963 26.34-19.3 33.4l57.6 115.2c9.111 18.22
                            32.71 23.4 48.61 10.68l89.63-71.7C499.5 152.9 496
                            144.1 496 136C496 113.9 513.9 96 536 96S576 113.9
                            576 136z" />
                </svg>
            </span>
        </div>

        <!-- If  not logged in -->
        <ng-container *ngIf="!authService.user">
            <a href="javascript:void(0);" (click)="copyTemplate()"
                class="bg-primary p-2 text-white text-xs font-medium flex items-center justify-center">
                <svg class="w-4 h-4 mr-1" viewBox="0 0 12 12" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M8.25 0.5H2.25C1.7 0.5 1.25 0.95 1.25 1.5V8.5H2.25V1.5H8.25V0.5ZM7.75 2.5H4.25C3.7 2.5 3.255 2.95 3.255 3.5L3.25 10.5C3.25 11.05 3.695 11.5 4.245 11.5H9.75C10.3 11.5 10.75 11.05 10.75 10.5V5.5L7.75 2.5ZM4.25 3.5V10.5H9.75V6H7.25V3.5H4.25Z" />
                </svg>
                Copy Template
            </a>
        </ng-container>

        <!-- If  logged in -->
        <ng-container *ngIf="authService.user">
            <!-- Free/Individual Pro -->
            <ng-container *ngIf="!authService.user.default_team">
                <a href="javascript:void(0);" (click)="copyTemplate()"
                    class="bg-primary p-2 text-white text-xs font-medium flex items-center justify-center">
                    <svg class="w-4 h-4 mr-1" viewBox="0 0 12 12" fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M8.25 0.5H2.25C1.7 0.5 1.25 0.95 1.25 1.5V8.5H2.25V1.5H8.25V0.5ZM7.75 2.5H4.25C3.7 2.5 3.255 2.95 3.255 3.5L3.25 10.5C3.25 11.05 3.695 11.5 4.245 11.5H9.75C10.3 11.5 10.75 11.05 10.75 10.5V5.5L7.75 2.5ZM4.25 3.5V10.5H9.75V6H7.25V3.5H4.25Z" />
                    </svg>
                    Copy Template
                </a>
            </ng-container>

            <!-- Team Docs -->
            <ng-container *ngIf="authService.user.default_team && permissions && permissions.COPY_SD_TEMPLATES">
                <a href="javascript:void(0);" (click)="copyTemplate()"
                    class="bg-primary p-2 text-white text-xs font-medium flex items-center justify-center">
                    <svg class="w-4 h-4 mr-1" viewBox="0 0 12 12" fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M8.25 0.5H2.25C1.7 0.5 1.25 0.95 1.25 1.5V8.5H2.25V1.5H8.25V0.5ZM7.75 2.5H4.25C3.7 2.5 3.255 2.95 3.255 3.5L3.25 10.5C3.25 11.05 3.695 11.5 4.245 11.5H9.75C10.3 11.5 10.75 11.05 10.75 10.5V5.5L7.75 2.5ZM4.25 3.5V10.5H9.75V6H7.25V3.5H4.25Z" />
                    </svg>
                    Copy Template
                </a>
            </ng-container>
        </ng-container>
    </div>
</div>