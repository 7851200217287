import { Component, OnInit } from '@angular/core';
import { HelperService } from "@app/core";

@Component({
  selector: 'app-g-suite-add-ons',
  templateUrl: './g-suite-add-ons.component.html'
})
export class GSuiteAddOnsComponent implements OnInit {

  constructor(private helperService : HelperService) { }

  ngOnInit(): void {
  }

  back() {
    this.helperService.jumpToPreviousPage();
  }

}
