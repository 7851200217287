import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FilterTagsModalComponent } from './components/modal/filter-tags-modal/filter-tags-modal.component';
import { UpgradePlanComponent } from './components/modal/upgrade-plan/upgrade-plan.component';
import { ComboBoxComponent } from './components/combo-box/combo-box.component';
import { UpgradeLicensesComponent } from './components/modal/upgrade-licenses/upgrade-licenses.component';

import { FilterTagsButtonComponent } from './components/filter-tags-button/filter-tags-button.component';
import { SwitchToProComponent } from './components/modal/switch-to-pro/switch-to-pro.component';
import { NavigationTabsComponent } from './components/navigation-tabs/navigation-tabs.component';
import { SearchFilterTagsComponent } from './components/search-filter-tags/search-filter-tags.component'
// import { SubscriptionModule } from '../modules/subscription/subscription.module';

@NgModule({
  declarations: [FilterTagsModalComponent, UpgradePlanComponent, ComboBoxComponent, UpgradeLicensesComponent, FilterTagsButtonComponent, SwitchToProComponent, NavigationTabsComponent, SearchFilterTagsComponent],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    // SubscriptionModule
  ],
  exports:[
    ComboBoxComponent,
    FilterTagsButtonComponent,
    NavigationTabsComponent,
    SearchFilterTagsComponent
  ]
})
export class SharedModule { }
