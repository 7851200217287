import { Component, PLATFORM_ID,  OnInit, Inject } from '@angular/core';
import { HelperService } from "@app/core";
import { isPlatformBrowser } from '@angular/common';
import { environment } from '@env/environment';

@Component({
  selector: 'app-get-started',
  templateUrl: './get-started.component.html'
})
export class GetStartedComponent implements OnInit {

  constructor(private helperService : HelperService,
    @Inject(PLATFORM_ID) private platformId: any,) { }

  ngOnInit(): void {
  }

  back() {
    this.helperService.jumpToPreviousPage();
  }

  redirectToGoogleLogin() {
    if (isPlatformBrowser(this.platformId)) {
      window.location.href = environment.backend_server_url + '/google-login'
    }
  }

}
