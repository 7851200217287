import { Component, OnInit, Inject } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute, Params } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { MetaService, GoogleAnalyticsService } from '@app/core';
import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID } from "@angular/core";
import { SwUpdate } from '@angular/service-worker';
import { environment } from '@env/environment';

declare var $: any;
declare var window:any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  title = 'startdeck';
  isSignDocument: boolean = true;
  logo = '';

  constructor(
    private router: Router,
    private metaService: MetaService,
    private activatedRoute: ActivatedRoute,
    private swUpdate: SwUpdate,
    private googleAnalyticsService: GoogleAnalyticsService,
    @Inject(PLATFORM_ID) private platformId: any,
  ) { }

  ngOnInit() {
    this.boot();
  }

  boot() {
    this.subscribeToUrlChanges();
    this.updateMetaInfoOnUrlChange();
    this.updateLogoOnUrlChange();
    this.checkForServiceWorkerUpdate();
    if (isPlatformBrowser(this.platformId)) {
      this.googleAnalyticsService.init();
     
      window.sharethisPropertyId = environment.sharethisPropertyId;
      let sharethisLibrary = '/assets/js/sharethis/sharethis.js';
      
      if (document.querySelectorAll('[src="' + sharethisLibrary + '"]').length == 0) {
        const script1 = document.createElement('script');
        script1.async = true;
        script1.src = sharethisLibrary;
        document.head.appendChild(script1);
      }
    }
  }

  subscribeToUrlChanges() {
    let event = this.router.events;

    // Hide or show navbar
    event.pipe(filter(ev => ev instanceof NavigationEnd)).subscribe(
      (event: any) => this.isSignDocument = event.url.includes('sign-document')
    );

    // Scroll to top on url change
    event.subscribe(e => {
      if ((e instanceof NavigationEnd) && isPlatformBrowser(this.platformId)) {

        this.activatedRoute.fragment.subscribe(frameElement => {
          if (!frameElement) {
            window.scrollTo(0, 0);
          }
        });
      }
    });

    // Hide hamburger icon
    event.subscribe(e => {
      if ((e instanceof NavigationEnd) && isPlatformBrowser(this.platformId)) {
        $('.small-screen-navigation-menu-items').slideUp();
        $('.hamburger-menu-toggle').attr('src', 'assets/images/hamburger-menu.svg');
      }
    });
  }

  updateMetaInfoOnUrlChange() {
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) { route = route.firstChild; }
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data)).subscribe((data) => {

        let title = "StartDeck";
        let metaDescription = "";
        let metaKeywords = "";

        if (data && data['title']) {
          title = data['title'];
        }

        if (data && data['metaDescription']) {
          metaDescription = data['metaDescription'];
        }

        if (data && data['metaKeywords']) {
          metaKeywords = data['metaKeywords'];
        }

        this.metaService.updateTitle(title);
        this.metaService.updateMetaDescription(metaDescription);
        this.metaService.updateKeywords(metaKeywords);

      });
  }

  updateLogoOnUrlChange() {
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) { route = route.firstChild; }
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data)).subscribe((data) => {
        this.logo = data['logo'];
      });
  }

  checkForServiceWorkerUpdate() {
    if (!isPlatformBrowser(this.platformId)) return;


    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe((event) => {
        if (confirm('New update is avaliable, do you want to refresh?')) {
          this.swUpdate.activateUpdate().then(() => document.location.reload());
        }
      });
    } else {
      console.error('Service Worker is not enabled.');
    }
  }
}

