<div class="modal in p-4" id="switch">
    <div class="modal-dialog max-w-5xl animated-from-top relative rounded">
        <div class="modal-body p-4">
            <app-copy-workflow></app-copy-workflow>
            <div class="flex justify-end md:justify-center">
                <!-- <span class="text-gray-600">
                    <input class="check-label-active mr-px" [(ngModel)]="donotShowModalCheckbox" type="checkbox" name="startdeck_template_workflow" id="startdeck_template_workflow">
                    <label class="align-top text-xs" for="startdeck_template_workflow">
                        Don't show this again
                    </label>
                </span> -->
                
                <a href="javascript:void(0);" (click)="setShowModalInStorage()" class="bg-primary inline-flex items-center px-4 sm:px-5 py-1 sm:py-1.5 text-white rounded text-xs sm:text-sm focus:outline-none hover:shadow-btn">
                    Okay
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 10h4.764a2 2 0 011.789 2.894l-3.5 7A2 2 0 0115.263 21h-4.017c-.163 0-.326-.02-.485-.06L7 20m7-10V5a2 2 0 00-2-2h-.095c-.5 0-.905.405-.905.905 0 .714-.211 1.412-.608 2.006L7 11v9m7-10h-2M7 20H5a2 2 0 01-2-2v-6a2 2 0 012-2h2.5" />
                    </svg>
                </a>
            </div>
        </div>      
    </div>
</div>
            

