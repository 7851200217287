import { Component, OnInit } from '@angular/core';
import { ApiService, HelperService, AuthService } from '@app/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {

  pages: any = null;
  assistants:any=null;

  constructor(
    public authService: AuthService,
    private apiService: ApiService,
    private helperService: HelperService

  ) { }

  ngOnInit(): void {
    

    this.apiService.get('p').subscribe((response) => {
      if (response.error) {
        this.helperService.navigateToUrl('/');
      } else {
        this.pages = response.pages;
        this.assistants=response.assistants
        
      }
    });
  }
}
