import { Component, OnInit } from '@angular/core';
import { HelperService, AuthService } from "@app/core";

@Component({
  selector: 'app-subscription-price',
  templateUrl: './subscription-price.component.html'
})
export class SubscriptionPriceComponent implements OnInit {

  isUserLoggedIn = false;
  user: any = null;
  hasTeamDocSubscription: boolean = false;
  hasProSubscription: boolean = false;

  constructor(
    private helperService: HelperService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.user = this.authService.user;
    if(this.user){    
      this.isUserLoggedIn = this.authService.isLoggedIn;
      this.hasTeamDocSubscription = this.isUserLoggedIn && this.user.subscription_plan == 'team-docs';
      this.hasProSubscription = this.isUserLoggedIn && this.user.subscription_plan == 'pro';
    }
   
  }

  freePlanClickEvent() {
    this.helperService.navigateToUrl('/login');
  }

  proPlanClickEvent() {
    if (this.hasProSubscription) return;

    this.isUserLoggedIn
      ? this.helperService.navigateToUrl('/subscription/pro')
      : this.helperService.navigateToUrl('/login?action=pro&data=');
  }

  teamDocsPlanClickEvent() {
    if (this.hasTeamDocSubscription) return;
    this.isUserLoggedIn
      ? this.helperService.navigateToUrl('/subscription/team-docs')
      : this.helperService.navigateToUrl('/login?action=team-docs&data=');
  }
}
