import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {HelperService} from '../services/helper.service';
import * as moment from 'moment-timezone';
import { Observable, throwError} from 'rxjs';
import { catchError, map, takeUntil} from 'rxjs/operators';
import { concat, delay } from 'lodash';
import { response } from 'express';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  timezone :any = null;

  constructor(
    private helperService: HelperService,
    private httpClient: HttpClient,
  ) {
    this.timezone = moment.tz.guess(true);
  }

  checkAuthErrorResponse(response : any){
   if (response && response.AUTH_ERROR) {
      this.helperService.navigateToUrl('logout?login=true');
      this.helperService.errorMessage("Session Expired! Please login again to continue.");
      this.helperService.unBlockUI();//do not delete it
      throw "AUTH_ERROR: Session Expired";
    }else{
      return true;
    }
  }

  get(url: string, headers = {}) : Observable<any> {
    let self = this;
    //Current Version of Angular is caching http get requests. We have to disable this feature.
    //Workaround: I am adding salt to make it new API request.
	  let updatedUrl = this.helperService.apiUrl(url);
    if(updatedUrl.indexOf("?") == -1){
      updatedUrl += `?salt=${Date.now()}`;
    }else{
      updatedUrl += `&salt=${Date.now()}`;
    }

    return this.httpClient.get(updatedUrl, {
      headers: new HttpHeaders({
        'timezone': this.timezone,
        'authToken': localStorage.getItem('authToken') || '',
        ...headers
      })
    }).pipe(map(response=>{
         self.checkAuthErrorResponse(response);
         return response;
    }));
  }

  post(url: string, payload : any = null, headers = {}) : Observable<any> {
    let self = this;
    return this.httpClient.post(this.helperService.apiUrl(url), payload, {
      headers: new HttpHeaders({
        'timezone': this.timezone,
        'authToken': localStorage.getItem('authToken') || '',
        ...headers
      })
    }).pipe(map(response=>{
      self.checkAuthErrorResponse(response);
      return response;
    }));


  }

  delete(url: string, payload:any = null, headers:any = {}) {

    let self = this;
    return this.httpClient.delete(this.helperService.apiUrl(url), {
      headers: new HttpHeaders({
        'timezone': this.timezone,
        'authToken': localStorage.getItem('authToken') || '',
        ...headers
      }),
      params:payload
    }).pipe(map(response=>{
      self.checkAuthErrorResponse(response);
      return response;
    }));

  }

  patch(url: string, payload = null, headers = {}) {
    let self = this;
    return this.httpClient.patch(this.helperService.apiUrl(url), payload, {
      headers: new HttpHeaders({
        'timezone': this.timezone,
        'authToken': localStorage.getItem('authToken') || '',
        ...headers
      })
    }).pipe(map(response=>{
      self.checkAuthErrorResponse(response);
      return response;
    }));
  }


}
