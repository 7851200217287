<!-- Search Filter Tags Dropdown: Mobile -->
<!-- <div class="relative">
    <a href="javascript:void(0);" (click)="toggleTagsDropdown()" class="bg-white bg-opacity-25 border border-gray-white flex font-medium items-center justify-between h-10 px-3 py-2 rounded md:hidden text-gray-300 text-xs">
        <span class="truncate"> {{ activeTag.tag }} </span>
        <i class="fa fa-caret-down text-base"></i>
    </a>

    <div *ngIf="showDropDown" class="md:hidden bg-white shadow-dropdown absolute rounded z-10 p-0.5 dropdown-arrow-top-right" style="top: 52px; right: 0;">
        <div class="divide-y divide-light w-60 overflow-auto max-h-52 rounded text-gray-600 text-xs px-2.5 filter-tags-dropdown">
            <a *ngFor="let tag of uniqueTags" href="javascript:void(0);" [routerLink]="'/' + tag.slug" class="flex items-center justify-between py-2 text-gray-300 font-medium">
                {{ tag.tag }}
                <img *ngIf="activeTag && activeTag.slug == tag.slug" src="assets/images/icon-check-primary.svg" class="inline-block w-3.5 ml-1">
            </a>
        </div>
    </div>
</div> -->


<!-- Button Filter Tags: Tablet/Desktop -->
<ul class="sidebar-template-tags hidden md:flex flex-col list-none p-0 m-0 -mt-2">
    <li class="tags" *ngFor="let tag of allTags" [ngClass]="activeTag && activeTag.tag == tag.tag ? 'active':''">
        <a class="tag-item" [routerLink]="'/' + tag.slug">
            {{tag.tag}} Templates
        </a>
    </li>
</ul>