import { Component, OnInit } from '@angular/core';
import { HelperService, AuthService } from '@app/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  template: ''
})
export class LogoutComponent implements OnInit {

  constructor(private authService: AuthService, private helperService: HelperService, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    if (this.authService.logout()) {
      this.helperService.navigateToUrl('/');
      // this.activatedRoute.queryParams.subscribe(params => {
      //   if (params.login) {
      //       this.helperService.navigateToUrl('login');
      //   }else{
      //       this.helperService.navigateToUrl('/');
      //   }
      // });
    }
  }


}
