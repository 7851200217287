import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Params } from "@angular/router";
import { TemplateLibraryService } from '../../template-library.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-landing-section',
  templateUrl: './landing-section.component.html',
})
export class LandingSectionComponent implements OnInit {

  // data : any = {};
  @Input() data = {
    "tag":"",
    "description":""
  }

  constructor(
    private activeRoute: ActivatedRoute,
    private templateLibraryService : TemplateLibraryService,
    private router : Router
  ) { }

  ngOnInit(): void {
    
  }

}
