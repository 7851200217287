import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { HelperService } from '@app/core';
import { TemplateLibraryService } from '../../template-library.service';
import { ActivatedRoute, Params } from "@angular/router";
import { find } from 'lodash';
import { Router } from '@angular/router';
@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html'
})

export class TagsComponent implements OnInit {

  @Output() selectedTag: EventEmitter<any> = new EventEmitter<any>();

  @Input() allTags: Array<any> = [];
  @Input() selectedTagUrl: any;
  activeTag: any = {};
  fullScreen = true;
  showDropDown = false;
  route: string | undefined = '';

  constructor(
    private helperService: HelperService,
    private templateLibraryService: TemplateLibraryService,
    private activeRoute: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {

    this.activeRoute.url.subscribe(url => {
      this.route = this.router.url.replace(/^\//, "");
      this.initTags();
      if (this.helperService.isMobile()) this.fullScreen = false;
    });
  }

  initTags(){
    this.activeRoute.params.subscribe((params: Params) => {
      this.showDropDown = false;
      this.activeTag = find(this.allTags, (t) => t.slug == this.route || t.url == this.selectedTagUrl);
      this.selectedTag.emit(this.activeTag);
    });
  }

  toggleTagsDropdown() {
    if (!this.showDropDown) {
      this.showDropDown = true;
      return;
    } else {
      this.showDropDown = false;
      return;
    }
  }

}
