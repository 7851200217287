<div class="modal in p-4" id="upgrade-plan">
    <div class="modal-dialog max-w-3xl animated-from-top relative rounded">
        <!-- Close Icon -->
        <i (click)="close()"
            class="fa fa-times text-sm mt-1 leading-6 text-gray-900 opacity-25 cursor-pointer modalclose absolute right-0 mr-4">
        </i>

        <div class="modal-body p-4 border-b border-gray-white">
            <h4 class="text-xl font-bold text-center mb-2">Upgrade Plan for your Account</h4>

            <p class="text-center text-sm text-gray-600 font-normal">
                You are currently on the free version and have reached montly free limits for:
            </p>

            <div class="flex flex-wrap items-center my-3 p-4 bg-smoke-white rounded-md">
                <div class="w-full sm:w-6/12 text-gray-white text-center hidden sm:block">
                    <img class="inline-block w-48" alt="Upgrade Plan" src="assets/images/upgrade-plan-infographic.png">
                </div>
                <div class="w-full sm:w-6/12">
                    <ul
                        class="text-left bullet-primary-check text-gray-600 font-normal text-xs leading-5 pl-4 sm:text-sm sm:leading-6">

                        <!-- Templates -->
                        <li class="mb-3 sm:mb-4">
                            Copy {{ free_limit }} Templates<br>
                            {{ calculateRemainingSDTemplates() }}
                        </li>

                        <!-- Projects -->
                        <li class="mb-3 sm:mb-4">
                            Create {{ free_limit }} Projects<br>
                            {{ calculateRemainingProjects() }}
                        </li>

                        <!-- Signature -->
                        <li>
                            Sign and/or Deliver {{ free_limit }} Documents <br>
                            {{ calculateRemainingSignature() }}
                        </li>
                    </ul>
                </div>
            </div>
            <p class="text-center text-sm text-gray-600 font-normal">
                Free services will be restored again next month. To continue now, please upgrade your plan. Cancel
                anytime.
            </p>
        </div>
        <div class="p-4 text-center">
            <a href="javascript:void(0);" [routerLink]="'/subscription/pro'" (click)="close()"
                class="bg-primary inline-block px-4 py-2 text-white rounded text-sm tracking-wide font-medium focus:outline-none hover:shadow-btn transition-all duration-300">
                Upgrade Now
            </a>
            <div
                class="text-left bg-gray-light bg-opacity-10 text-gray-300 italic text-xs px-2 py-1 border-l-2 border-gray-900 mt-3">
                Note: StartDeck automation Add-ons remain fully functional, and most dashboard functions are fully
                functional.
            </div>
        </div>
    </div>
</div>