import { Injectable } from '@angular/core';
import {Router, NavigationEnd} from '@angular/router';
import { environment }  from '../../../environments/environment'

declare var gtag:Function;

@Injectable({
	providedIn: 'root'
})

export class GoogleAnalyticsService {
	
	constructor(
		public router: Router
	) 
	{}

	init() 
	{
		if (!environment.production) {
			return;
		}

		this.listenForRouteChanges();

		try {

			const script1 = document.createElement('script');
			script1.async = true;
			script1.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.GOOGLE_ANALYTICS_ID;
			
			document.head.appendChild(script1);
	  
			const script3 = document.createElement('script');
			script3.async = true;
			script3.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.GOOGLE_TAG_ID;
			
			document.head.appendChild(script3);
			
			const script2 = document.createElement('script');
			script2.innerHTML = `
			  window.dataLayer = window.dataLayer || [];
			  function gtag(){dataLayer.push(arguments);}
			  gtag('js', new Date());
			  gtag('config', '` + environment.GOOGLE_ANALYTICS_ID + `', {'send_page_view': false});
			  gtag('config', '`+ environment.GOOGLE_TAG_ID +`');
			`;
			
			document.head.appendChild(script2);

		} catch (ex) {
		
			console.error('Error appending google analytics');
		
			console.error(ex);
		}
	
	}

	private listenForRouteChanges() {
		this.router.events.subscribe(event => {
		  	if (event instanceof NavigationEnd) {
				gtag('config', environment.GOOGLE_ANALYTICS_ID, {
			  		'page_path': event.urlAfterRedirects,
				});
				gtag('config', environment.GOOGLE_TAG_ID, {
					'page_path': event.urlAfterRedirects,
			  	});
		  	}
		});
	}
}