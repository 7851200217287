import { Component, OnInit, Input, OnChanges, SimpleChanges, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { HelperService, ApiService, AuthService } from '@app/core';
import { environment } from '@env/environment';
import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID } from "@angular/core";
declare var $: any;

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html'
})
export class NavComponent implements OnInit, OnChanges {
  @Input('logo') logo: any;

  netState: boolean = true;
  localStorage = null;
  environment = environment;
  isMobileDropdownOpen = false;

  constructor(
    public authService: AuthService,
    public router: Router,
    public _helper: HelperService,
    private apiService: ApiService,
    public helperService: HelperService,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    // this.authService.authenticate(true);
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.logo = (this.authService.user) ? this.logo : HelperService.getLogo(1);
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.netState = navigator.onLine;
      this.subscribeToInternetChangeEvents();
      this.localStorage = JSON.parse(localStorage.getItem('user') as string);
    }
   
  }

  ngAfterViewInit() {
    // this.hamburgerMenuNavigationBarHandler();
    this._helper.bindWindowClickEvent('login-dropdown-toggle-action', 'login-dropdown-menu');
    this._helper.bindWindowClickEvent('login-dropdown-toggle-action', 'manage-team-menu');
  }

  hamburgerMenuNavigationBarHandler() {
    this.isMobileDropdownOpen = !this.isMobileDropdownOpen
  }

  subscribeToInternetChangeEvents() {
    this._helper.netOnline().subscribe((res) => {
      this.netState = true;
    },
    );

    this._helper.netOffline().subscribe((res) => {
      this.netState = false;
    });
  }

  checkIfUrlMatch(array: string[], mode: boolean = false) {
    let flag = false;
    array.forEach(
      (item) => {
        if (this.router.isActive(item, mode)) {
          flag = true;
        }
      }
    );
    return flag;
  }

  onNavigationClicked() {
    let el = document.getElementById('nav-toggler-button');
    if (el) {
      el.click();
    }

  }

  setDefaultTeam(team) {

    if (team.has_subscription_expired) {
      return;
    }

    let message = "This action will display '" + team.name + "' Templates, Projects, Contacts and Content Library entries in StartDeck. \n\n" + team.owner_name + " is the owner of " + team.name + "'s content. \n\nYou can switch back to personal Templates and Projects at any time.";

    if (confirm(message)) {
      let request = this.apiService.get('team-docs/teams/' + team.uid + '/set-default');

      request.subscribe((res: any) => {
        if (res && !res.error) {
          window.location.href = '/projects'
        } else {
          this._helper.errorMessage(res.message);
        }
      });
    }

  }

  setMyDrive() {

    let message = "This action will set your personal StartDeck templates, projects, content library and client data as the default view. \n\nSwitch between private and teams as needed - all data and settings are retained.";

    if (confirm(message)) {
      this.apiService.get('set-my-drive').subscribe(() => window.location.href = '/projects');
    }
  }

  largeScreenNavigationMenutoggler() {
    $('.login-dropdown-toggle-action').next('.login-dropdown-menu').toggle();
  }

  manageTeamToggler() {
    $('.manage-team-action').next('.manage-team-menu').toggle();
  }

}
