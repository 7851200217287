<div class="bg-white">
  <div class="container mx-auto">
      <div class="flex sm:items-center px-4 py-3">
          <div class="text-gray-300 font-medium flex-1 text-center text-sm sm:text-base">
            Contact us
          </div>
      </div>
  </div>
</div>

<div class="container mx-auto px-4 py-4 min-h-90-vh lg:min-h-70-vh xl:min-h-screen mb-20">
    <div class="grid md:grid-cols-3 gap-4 md:gap-8">
      <!--Support/Feedback-->
      <div class="bg-white shadow-card p-6 lg:p-8 rounded text-center border-t-4 border-success">
        <img class="inline-block" src="assets/images/contact/support-feedback.svg">
        <h4 class="text-lg text-gray-300 font-bold mt-4 mb-2">Support/Feedback</h4>
        <a class="text-primary text-sm block underline mb-1" href="mailto:support@startdeck.com">support@startdeck.com</a>
        <a class="text-primary text-sm block" [routerLink]="'/knowledge-base'"> Knowledge Base</a>
      </div>

      <!--Email-->
      <div class="bg-white shadow-card p-6 lg:p-8 rounded text-center border-t-4 border-primary">
        <img class="inline-block" src="assets/images/contact/envelope.svg">
        <h4 class="text-lg text-gray-300 font-bold mt-4 mb-2">Email</h4>
        <p class="text-gray-600 text-sm mb-1">Tom Armstrong <a class="text-primary underline" href="mailto:tom@startdeck.com">tom@startdeck.com</a></p>
        <a class="text-primary text-sm" [routerLink]="'/p/about-startdeck'">About StartDeck</a>
      </div>

      <!--Location-->
      <div class="bg-white shadow-card p-6 lg:p-8 rounded text-center border-t-4 border-danger">
        <img class="inline-block" src="assets/images/contact/map-marker.svg">
        <h4 class="text-lg text-gray-300 font-bold mt-4 mb-2">Office Location</h4>
        <p class="text-gray-600 text-sm">1 Bridge Street, Suite 102 Plymouth, NH 03245</p>
      </div>
    </div>
</div>