import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HelperService, AuthService } from '@app/core';
import { TagsAndDescriptionModalComponent } from '../../modals/tags-and-description-modal/tags-and-description-modal.component';
import { TemplateService } from '../../template.service';
import { UpgradePlanComponent } from '@app/shared/components/modal/upgrade-plan/upgrade-plan.component';
import { ShareModalComponent } from '../../modals/share-modal/share-modal.component';

declare var window:any;
@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
})
export class TemplateComponent implements OnInit {
  @Input('template') template: any;
  @Input('permissions') permissions: any;

  @Output() premiumTemplateAccessDenied = new EventEmitter<any>();

  dataTemplateUrl:any;
  dataMailMessage:any;
  dataMailSubject:any;

  constructor(
    private templateService: TemplateService,
    private helperService: HelperService,
    public authService: AuthService
  ) { }

  ngOnInit(): void {
  
    this.dataTemplateUrl = this.helperService.siteUrl('/template-library/'+ this.template.slug);
    this.dataMailMessage = "I thought this "+this.template.name+" template would be of interest to you. It's a short-form narrative designed for Google docs \n "+ this.dataTemplateUrl;
    this.dataMailSubject = this.template.name + " template"; 

    setTimeout(() => {
      if( window.__sharethis__){
        window.__sharethis__.initialize();
      }
    }, 2500);
   }

  getHeadingText() {
    return this.helperService.truncateString(this.template.name, 50);
  }

  getDescriptionText() {
    return this.template.description
      ? this.helperService.truncateString(this.template.description, 70)
      : 'N/A';
  }

  openTagAndDescriptionModal() {
    this.helperService.openModal(TagsAndDescriptionModalComponent, {
      template: this.template
    });
  }

  copyTemplate() {
    if (this.authService.isLoggedIn) {
      this.doCopy();
      return;
    }

    if (!this.authService.isLoggedIn) {
      if (this.template.is_premium) {
        this.helperService.navigateToUrl('/login?action=copy_premium_template&data=' + this.template.uid);
      } else {
        this.helperService.navigateToUrl('/login?action=copy_template&data=' + this.template.uid);
      }
      return;
    }
  }

  doCopy() {

    this.helperService.blockUI('Creating copy, this may take a minute ...');

    this.templateService.copy(this.template.uid).subscribe((response: any) => {

      if (response && response.error == true) {

        if (response.error_type == 'UPGRADE_PLAN') {
          this.helperService.openModal(UpgradePlanComponent)
          this.helperService.unBlockUI();
        }
        else if (response.error_type == 'PREMIUM_TEMPLATE') {
          this.premiumTemplateAccessDenied.emit(true);
          this.helperService.unBlockUI();
        } else {
          this.helperService.unBlockUI();
          this.helperService.errorMessage(response.message);
        }
      } else {
        this.helperService.navigateToUrl('user-templates?' + 'copy=true&uid=' + response.userTemplateUid);
        this.helperService.unBlockUI();
      }
    });
  }
}
